export const Permissions = {
    SIMULACAO_APOSENTADORIA: {
        SIMULAR_APOSENTADORIA: ['ROLE_CONSULTA_DADOS_SIMULADOR', 'ROLE_CONSULTA_PERIODOS_SIMULADOR', 'ROLE_SIMULA_REGRA_SIMULADOR', 'ROLE_INSERE_TEMPO_ADICIONAL_SIMULADOR', 'ROLE_REL_REGRAS_APLICAVEIS_SIMULADOR', 'ROLE_REL_PERIODO_CONT_SIMULADOR'],
        SIMULAR_APOSENTADORIA_ATENDENTE: ['ROLE_CONS_DADOS_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_SIMULA_REGRAS_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_CONS_PER_CONTR_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_INS_TEMPO_ADI_AVERB_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_REL_REGRAS_APLICAVEIS_VF_SIMULADOR', 'ROLE_REL_PERIODO_CONT_VF_SIMULADOR'],
        MENU_APOSENTADORIA: ['ROLE_CONSULTA_DADOS_SIMULADOR', 'ROLE_CONSULTA_PERIODOS_SIMULADOR', 'ROLE_SIMULA_REGRA_SIMULADOR', 'ROLE_INSERE_TEMPO_ADICIONAL_SIMULADOR', 'ROLE_REL_REGRAS_APLICAVEIS_SIMULADOR', 'ROLE_REL_PERIODO_CONT_SIMULADOR', 'ROLE_CONS_DADOS_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_SIMULA_REGRAS_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_CONS_PER_CONTR_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_INS_TEMPO_ADI_AVERB_OUTRO_SERVIDOR_SIMULADOR', 'ROLE_REL_REGRAS_APLICAVEIS_VF_SIMULADOR', 'ROLE_REL_PERIODO_CONT_VF_SIMULADOR'],
        CONSULTAR_RESUMO: ['ROLE_CONSULTA_DADOS_SIMULADOR'],
        CONSULTAR_RESUMO_OUTRO_SERVIDOR: ['ROLE_CONS_DADOS_OUTRO_SERVIDOR_SIMULADOR'],
        CONSULTAR_PERIODOS: ['ROLE_CONSULTA_PERIODOS_SIMULADOR'],
        CONSULTAR_PERIODOS_CONTRIBUICAO_OUTRO_SERVIDOR: ['ROLE_CONS_PER_CONTR_OUTRO_SERVIDOR_SIMULADOR'],
        REGRAS_APLICAVEIS: ['ROLE_SIMULA_REGRA_SIMULADOR'],
        REGRAS_APLICAVEIS_OUTRO_SERVIDOR: ['ROLE_SIMULA_REGRAS_OUTRO_SERVIDOR_SIMULADOR'],
        INSERIR_TEMPO_ADICIONAL: ['ROLE_INSERE_TEMPO_ADICIONAL_SIMULADOR'],
        INSERIR_TEMPO_ADICIONAL_OUTRO_SERVIDOR: ['ROLE_INS_TEMPO_ADI_AVERB_OUTRO_SERVIDOR_SIMULADOR'],
        GERAR_PDF_REGRAS_APLICAVEIS: ['ROLE_REL_REGRAS_APLICAVEIS_SIMULADOR'],
        GERAR_PDF_PERIODOS_CONTRIBUICAO: ['ROLE_REL_PERIODO_CONT_SIMULADOR'],
        GERAR_PDF_REGRAS_APLICAVEIS_FINAL: ['ROLE_REL_REGRAS_APLICAVEIS_VF_SIMULADOR'],
        GERAR_PDF_PERIODOS_CONTRIBUICAO_FINAL: ['ROLE_REL_PERIODO_CONT_VF_SIMULADOR']
    },
    SIMULACAO_PENSAO: {
        MEMU_PENSAO: ['ROLE_CONS_CPF_SEGURADO_PENSAO_SIMULADOR', 'ROLE_CALCULA_PENSAO_SIMULADOR'],
        SIMULACAO_PENSAO: ['ROLE_CONS_CPF_SEGURADO_PENSAO_SIMULADOR', 'ROLE_CALCULA_PENSAO_SIMULADOR'],
        CONSULTAR_CPF_SEGURADO: ['ROLE_CONS_CPF_SEGURADO_PENSAO_SIMULADOR'],
        CALCULAR_PENSAO: ['ROLE_CALCULA_PENSAO_SIMULADOR'],
        INSERIR_DEPENDENTES: ['ROLE_INSERE_DEPENDENTE_CALCULO_SIMULADOR'],
        IMPRIMIR_CALCULO_SIMULACAO: ['ROLE_IMPRIME_CALCULO_VS_SIMULADOR'],
        IMPRIMIR_CALCULO_FINAL: ['ROLE_IMPRIME_CALCULO_VF_SIMULADOR']
    },
    INDICE_CONTRIBUICAO: {
        MENU_INDICE_CONTRIBUICAO: ['ROLE_CADASTRA_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_EDITA_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_EXCLUI_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_CONS_HISTORICO_INDICES_CONTRIBUICAO_SIMULADOR'],
        INDICE_CONTRIBUICAO: ['ROLE_CADASTRA_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_EDITA_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_EXCLUI_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_CONS_HISTORICO_INDICES_CONTRIBUICAO_SIMULADOR'],
        CONSULTAR_HISTORICO_INDICES: ['ROLE_CONS_HISTORICO_INDICES_CONTRIBUICAO_SIMULADOR'],
        CADASTRAR_INDICE: ['ROLE_EDITA_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_CADASTRA_INDICE_CONTRIBUICAO_SIMULADOR'],
        EDITAR_INDICE: ['ROLE_EDITA_INDICE_CONTRIBUICAO_SIMULADOR'],
        EXCLUIR_INDICE: ['ROLE_EXCLUI_INDICE_CONTRIBUICAO_SIMULADOR'],
        BOTAO_CADASTRO: ['ROLE_CADASTRA_INDICE_CONTRIBUICAO_SIMULADOR'],
        ACOES_TABELA: ['ROLE_EXCLUI_INDICE_CONTRIBUICAO_SIMULADOR', 'ROLE_EDITA_INDICE_CONTRIBUICAO_SIMULADOR']
    },
    SALARIO_MINIMO_MAXIMO: {
        MENU_SALARIO: ['ROLE_CONS_HISTORICO_SALARIOS_SIMULADOR', 'ROLE_CADASTRA_SALARIOS_SIMULADOR', 'ROLE_EDITA_SALARIOS_SIMULADOR', 'ROLE_EXCLUI_SALARIOS_SIMULADOR'],
        SALARIO_MINIMO: ['ROLE_CONS_HISTORICO_SALARIOS_SIMULADOR', 'ROLE_CADASTRA_SALARIOS_SIMULADOR', 'ROLE_EDITA_SALARIOS_SIMULADOR', 'ROLE_EXCLUI_SALARIOS_SIMULADOR'],
        CONSULTAR_HISTORICO_SALARIO: ['ROLE_CONS_HISTORICO_SALARIOS_SIMULADOR'],
        CADASTRAR_SALARIO: ['ROLE_CADASTRA_SALARIOS_SIMULADOR', 'ROLE_EDITA_SALARIOS_SIMULADOR'],
        EDITAR_SALARIO: ['ROLE_EDITA_SALARIOS_SIMULADOR'],
        EXCLUIR_SALARIO: ['ROLE_EXCLUI_SALARIOS_SIMULADOR'],
        BOTAO_CADASTRO: ['ROLE_CADASTRA_SALARIOS_SIMULADOR'],
        ACOES_TABELA: ['ROLE_EXCLUI_SALARIOS_SIMULADOR', 'ROLE_EDITA_SALARIOS_SIMULADOR']
    },
    INDICE_BENEFICIOS: {
        MENU_INDICE_BENEFICIOS: ['ROLE_CONS_HISTORICO_INDICES_BENEFICIOS_SIMULADOR', 'ROLE_CADASTRA_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EDITA_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EXCLUI_INDICE_BENEFICIOS_SIMULADOR'], // TODAS PERMISAO
        INDICES_BENEFICIOS: ['ROLE_CONS_HISTORICO_INDICES_BENEFICIOS_SIMULADOR', 'ROLE_CADASTRA_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EDITA_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EXCLUI_INDICE_BENEFICIOS_SIMULADOR'],
        CONSULTAR_HISTORICO_INDICES: ['ROLE_CONS_HISTORICO_INDICES_BENEFICIOS_SIMULADOR'],
        CADASTRAR_INDICE: ['ROLE_CADASTRA_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EDITA_INDICE_BENEFICIOS_SIMULADOR'],
        EDITAR_INDICE: ['ROLE_EDITA_INDICE_BENEFICIOS_SIMULADOR'],
        EXCLUIR_INDICE: ['ROLE_EXCLUI_INDICE_BENEFICIOS_SIMULADOR'],
        BOTAO_CADASTRO: ['ROLE_CADASTRA_INDICE_BENEFICIOS_SIMULADOR'],
        ACOES_TABELA: ['ROLE_EXCLUI_INDICE_BENEFICIOS_SIMULADOR', 'ROLE_EDITA_INDICE_BENEFICIOS_SIMULADOR']
    },
    VINCULO_INSTI_PENSIONISTA: {
        MENU_INDICES_PROCESSO: ['ROLE_CONS_CPF_SEGURADO_VINC_IP_SIMULADOR'],
        CONSULTAR_CPF_SEGURADO: ['ROLE_CONS_CPF_SEGURADO_VINC_IP_SIMULADOR'],
        CONSULTAR_PROCESSO_VINCULADO: ['ROLE_CONS_PROCESSOS_VINCULADOS_SIMULADOR'],
        CADASTRAR_PROCESSO: ['ROLE_CADASTRA_PROCESSO_SIMULADOR', 'ROLE_EDITA_PROCESSO_SIMULADOR'],
        EDITAR_PROCESSO: ['ROLE_EDITA_PROCESSO_SIMULADOR'],
        EXCLUIR_PROCESSO: ['ROLE_EXCLUI_PROCESSO_SIMULADOR'],
        BOTAO_CADASTRAR_PROCESSO: ['ROLE_CADASTRA_PROCESSO_SIMULADOR'],
        ACOES_TABELA: ['ROLE_EXCLUI_PROCESSO_SIMULADOR', 'ROLE_EDITA_PROCESSO_SIMULADOR']
    },
    ADMINISTRACAO_SISTEMA: {
        ADMIN_USUARIO: ['ROLE_GESTOR_USUARIOS_SIMULADOR'],
        AUDITORIA: ['ROLE_GESTOR_AUDITORIA'] 
    },
    BENEFICIO_ESPECIAL: {
        BENEFICIO: ['ROLE_BENEFICIO_ESPECIAL_SIMULADOR']
    }
}
