import PropTypes from "prop-types";
import { AlignType } from "../TableDemostrativa/alignType";

class Column {

    constructor(object = []) {
        this.name = object.name;
        this.align = object.align;
        this.visible = object.visible;
        this.width = object.width;
    }

    addColumn = (arrayColumnTable) => {
        let arrayConfig = [];
        arrayColumnTable.map(item => {
            let arrayColumnOneTable = [];
            item.map(column => {
                arrayColumnOneTable.push(new Column(column));
                return column;
            });
            arrayConfig.push(arrayColumnOneTable);
            return item;
        });
        return arrayConfig;
    }

}

Column.propTypes = {
    name: PropTypes.string.isRequired,
    align: PropTypes.oneOf(Object.keys(AlignType)),
    visible: PropTypes.bool,
    width: PropTypes.string,
};

export const Columns = PropTypes.arrayOf(PropTypes.instanceOf(Column));
export default Column;