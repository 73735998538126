import React from 'react'
import { Permissions } from '../../Constantes/permissions.constants';
import Auditoria  from 'gp-auditoria';
import { Message } from '../../Constantes/message.constants';
import { Secured } from '../../component';
import config from '../../config';


const AdministracaoAuditoria = () => {

    return (
        <>
            <Secured hasAllRole={Permissions.ADMINISTRACAO_SISTEMA.AUDITORIA} isShowErro403={true}>
                <Auditoria urlAuditoria={config.AUDITORIA} message={Message} aplicacao='simulador-api'></Auditoria>
            </Secured>

        </>
    )

}

export default AdministracaoAuditoria;