import React from "react";
import { Row, Col, Button, Modal } from "antd";
import { Link, useParams } from "react-router-dom";
import {
  LeftOutlined,
  DeleteOutlined,
  SaveOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { returnStringAction } from "../../helper/util";

function CRUDButtons(props) {
  const params = useParams();

  return (
    <>
      <Row
        gutter={10}
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "24px",
        }}
      >
        <Col>
          <Link to={props.linkBackButton}>
            <Button icon={<LeftOutlined />}>Voltar</Button>
          </Link>
        </Col>
        {params.id && (
          <>
            <Col>
              <Button
                type="primary"
                onClick={() => props.openDeleteModal()}
                danger
                icon={<DeleteOutlined />}
              >
                Excluir
              </Button>
            </Col>
          </>
        )}

        <Col>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => props.handleSubmit()}
            disabled={props.disableSaveButton}
            icon={<SaveOutlined />}
          >
            {params.id ? "Salvar Edição" : "Cadastrar"}
          </Button>
        </Col>
      </Row>

      <Modal
        open={props.openModal}
        onCancel={() => props.handleCancel()}
        footer={[
          <Button onClick={() => props.handleCancel()}>Cancelar</Button>,
          <Button
            type="primary"
            loading={props.loading}
            onClick={() => props.handleDelete()}
          >
            Sim
          </Button>,
        ]}
      >
        <Row>
          <Col span={24} style={{ minWidth: "100%" }}>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <WarningOutlined style={{ fontSize: "100px" }} />
              </div>
              <p style={{ textAlign: "center" }}>
                Deseja realmente{" "}
                <strong>{returnStringAction(props.action)} ?</strong>
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>{props.name}</strong>
              </p>

              {props.action !== "delete" ? props.customContent : null}
            </>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default CRUDButtons;
