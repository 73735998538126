import React from "react";
import { VictoryBar, VictoryChart, VictoryTheme, VictoryGroup } from "victory";
import "gp-painel/dist/index.css";
import { Card } from "antd";

const BarGrafico = ({ simulacaoBeneficioEspecial }) => {
  return (
    <Card style={{ position: "relative", height: "400px" }}>
      <VictoryChart
        theme={VictoryTheme.grayscale}
        domainPadding={{ x: 100 }}
        width={800}
        padding={{ left: 350, bottom: 50, right: 150 }}
      >
        <VictoryGroup
          offset={20}
          barRatio={0.1} // size of bar
          domainPadding={{ x: 10 }}
          colorScale={"qualitative"}
        >
          <VictoryBar
            horizontal={true}
            barWidth={20}
            data={[
              {
                x: "Contribuição Mensal",
                y: simulacaoBeneficioEspecial.beneficioRPPS
                  .valorMensalContribuicao,
              },
              /*{
                x: "Valor mensal previsto",
                y: simulacaoBeneficioEspecial.beneficioRPPS
                  .valorMensalPrevistoAposentadoriaLiquido,
              },*/
            ]}
            labels={({ datum }) => (datum.y ? "Atual" : "")}
            style={{
              data: { fill: "#12481a" },
              labels: { fill: "black" },
            }}
          ></VictoryBar>
          <VictoryBar
            horizontal={true}
            barWidth={20}
            data={[
              {
                x: "Contribuição Mensal",
                y: simulacaoBeneficioEspecial.beneficioEspecial
                  .valorMensalContribuicao,
              },
              /*{
                x: "Valor mensal previsto",
                y: simulacaoBeneficioEspecial.beneficioEspecial
                  .valorMensalPrevistoBeneficioEspecial,
              },*/
            ]}
            labels={({ datum }) => (datum.y ? "Benefício Especial" : "")}
            style={{
              data: { fill: "#c1c715" },
              labels: { fill: "black" },
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </Card>
  );
};

export default BarGrafico;
