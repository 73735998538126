import React, { useContext } from "react";
import "./style.scss";
import pinStyles from "./style.module.scss";
import { NavLink } from "react-router-dom";
import MenuContext from "../../state/Menu/Context";
import * as MenuActions from "../../state/Menu/actions";
import { Secured } from "../Security";
import { Permissions } from '../../Constantes/permissions.constants';
import MainHomeContext from '../../state/Home/Context';
import { getURL } from "../../helper/util";

const Menu = () => {
    const { menu, menuDispatch } = useContext(MenuContext);
    const { mainHome } = useContext(MainHomeContext);

    const handlePin = (e) => {
        menuDispatch(MenuActions.changePinned(!menu));
        e.preventDefault();
    };

    return (
        <nav className="">
            <div className={[menu ? pinStyles.unpined : null, 'sidebar '].join(" ")}>
                <ul className="nav flex-column top-control  d-block d-sm-block d-md-block d-lg-none">
                    <li className="nav-item">
                        <a className="nav-link" onClick={handlePin} href="/#">
                            <i className="fas fa-bars" />Menu
                        </a>
                    </li>
                </ul>
                <ul className="nav flex-column mediun-control">
                    <li className="nav-item">
                        <Secured hasAnyRole={Permissions.SIMULACAO_APOSENTADORIA.MENU_APOSENTADORIA}>
                            <NavLink className="nav-link" exact to="/" activeClassName='active'>
                                <i className="fas fa-file-contract" />Aposentadoria<span className="sr-only">Página Atual</span>
                            </NavLink>
                        </Secured>
                    </li>
                    <Secured hasAnyRole={Permissions.SIMULACAO_PENSAO.MEMU_PENSAO}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/pensao", 'pensao', mainHome)} >
                                <i className="fas fa-baby" />Pensão
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.INDICE_CONTRIBUICAO.MENU_INDICE_CONTRIBUICAO}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/contribuicao", 'contribuicao', mainHome)}>
                                <i className="fas fa-poll" />Correção de Contribuição
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.INDICE_BENEFICIOS.MENU_INDICE_BENEFICIOS}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/beneficios", "beneficios", mainHome)}>
                                <i className="fas fa-chart-line" />Correção de Benefício
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.SALARIO_MINIMO_MAXIMO.MENU_SALARIO}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/salario", "salario", mainHome)}>
                                <i className="fas fa-dollar-sign" />Salário Mínimo e Teto
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.VINCULO_INSTI_PENSIONISTA.MENU_INDICES_PROCESSO}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/processo", "processo", mainHome)}>
                                <i className="fas fa-folder-open" />Processos de Vínculo
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.ADMINISTRACAO_SISTEMA.ADMIN_USUARIO}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/administracao/usuario", "administracao", mainHome)}>
                                <i className="fas fa-user" />Administração do Usuário
                            </NavLink>
                        </li>
                    </Secured>
                    <Secured hasAnyRole={Permissions.ADMINISTRACAO_SISTEMA.AUDITORIA}>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={getURL("/administracao/auditoria", "auditoria", mainHome)}>
                                <i className="fas fa-list-alt" />Auditoria
                            </NavLink>
                        </li>
                    </Secured>

                </ul>
                <ul className="nav flex-column bottom-control float-right d-none d-lg-block d-xl-block">
                    <li className="nav-item">
                        <a className="nav-link" onClick={handlePin}>
                            <i className="fas fa-thumbtack" />
                        </a>
                    </li>
                </ul>
            </div>
        </nav >
    )
}

export default Menu;