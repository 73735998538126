import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Secured } from '../../../component';
import Datepicker, { DatepickerType } from 'gp-datepicker';
import 'gp-datepicker/dist/index.css';
import If from 'gp-if';
import MessageError from 'gp-message-error';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import useSecurityContext from '../../../component/Security/useSecurityContext';
import { Permissions } from '../../../Constantes/permissions.constants';
import { applyMaskMesAnoPorSeparador, MASK_MONETARIO, removeMaskMonetario, applyMaskMonetario } from '../../../helper/mask';
import useForm from '../../../helper/useForm';
import * as MessageActions from "../../../state/Message/actions";
import MessageContext from '../../../state/Message/Context';
import services from './../services';
import { Message } from '../../../Constantes/message.constants';
import MainHomeContext from '../../../state/Home/Context';
import { useHistory } from 'react-router-dom';
import { getURL } from '../../../helper/util';

const CadastroSalarioMinimoTeto = ({ match }) => {

    const { messageDispatch } = useContext(MessageContext);
    const { hasPermission } = useSecurityContext();
    const { mainHome } = useContext(MainHomeContext);
    let history = useHistory();
    let { id } = match.params;

    const initialValuesSchema = {
        referencia: '',
        minimo: '',
        teto: '',
    }


    const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
    const [isEditar, setIsEditar] = useState(false);

    const validationForm = {
        referencia: {
            required: true
        },
        minimo: {
            required: true
        },
        teto: {
            required: true
        }
    };

    useEffect(() => {
        if (id) {
            services.obter(id).then((response) => {
                if (response) {
                    setValuesSchema({
                        id: response.id,
                        referencia: formataMesAnoForDate(response.referencia),
                        minimo: applyMaskMonetario(response.minimo),
                        teto: applyMaskMonetario(response.teto),
                    });
                    setIsEditar(true);
                }
            }).catch(error => console.log(error));
        }
    }, [id]);

    const onSubmit = () => {
        if (isEditar) {
            onEditar();
        } else {
            adicionar();
        }
    }

    const adicionar = async () => {
        services.salvar(formataRequest()).then(response => {
            messageDispatch(MessageActions.addSucessMessage([Message.SALARIO_MINIMO_TETO_SUCESSO]));
            voltar();
        }).catch(error => console.log(error));
    }

    const formataRequest = () => {
        return {
            referencia: Number(format((values.referencia), 'yyyyMM')),
            minimo: removeMaskMonetario(values.minimo).replace(',', '.'),
            teto: removeMaskMonetario(values.teto).replace(',', '.')
        }
    }

    const openCalendar = (date) => {

        setValuesSchema({
            ...values,
            referencia: date
        });
    }

    const cancelar = () => {
        setValuesSchema(initialValuesSchema);
        setIsEditar(false);
    }

    const onEditar = () => {
        services.editar(values.id, formataRequest()).then(resp => {
            setIsEditar(false);
            messageDispatch(MessageActions.addSucessMessage([Message.SALARIO_MINIMO_TETO_EDITADO_SUCESSO]));
            voltar();
        }).catch(error => console.log(error));
    }

    const formataMesAnoForDate = (mesAno) => {
        let data = applyMaskMesAnoPorSeparador(mesAno, '-');
        return new Date(data + '-01 00:00:00');
    }

    const onCancelar = () => {
        voltar();
    }

    const voltar = () => history.push(getURL("/salario", 'salario', mainHome));

    const getPermissaoCadastrar = () => hasPermission({ hasAnyRole: Permissions.SALARIO_MINIMO_MAXIMO.BOTAO_CADASTRO });

    const { values, errors, handleChange, handleBlur, handleSubmit, handleUpdateValues, handleReset } =
        useForm(valuesSchema, onSubmit, validationForm, cancelar);

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    return (
        <Fragment>
            <Secured hasAnyRole={Permissions.SALARIO_MINIMO_MAXIMO.CADASTRAR_SALARIO} isShowErro403={true}>
                <div className='col-12'>
                    <Painel titulo='Salário Mínimo e Teto RGPS'>
                        <form onSubmit={handleSubmit}>
                            <h5 className='mt-4 mb-3'>Novo Índice</h5>
                            <div className='row'>
                                <Datepicker id='referencia' label='Data de Vigência'
                                    date={values.referencia}
                                    typeDatepicker={DatepickerType.MONTH_YEAR}
                                    errors={errors}
                                    classes="col-6 col-lg-3 col-xl-2"
                                    callback={openCalendar}
                                    onBlur={handleBlur}
                                />
                                <div className="form-group col-6 col-lg-3 col-xl-2 input-group-sm">
                                    <label className="col-form-label" htmlFor="minimo">
                                        Salário Mínimo
                                        </label>
                                    <MaskedInput id="minimo" name="minimo"
                                        className={`form-control ${errors.minimo && "is-invalid"}`}
                                        guide={false}
                                        mask={MASK_MONETARIO}
                                        placeholder='R$ 0,00'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.minimo}
                                        autoComplete="off" />
                                    <MessageError
                                        error={errors.minimo} />
                                </div>
                                <div className="form-group col-6 col-lg-3 col-xl-2 input-group-sm">
                                    <label className="col-form-label" htmlFor="teto">
                                        Teto RGPS
                                        </label>
                                    <MaskedInput id="teto" name="teto"
                                        className={`form-control ${errors.teto && "is-invalid"}`}
                                        guide={false}
                                        mask={MASK_MONETARIO}
                                        placeholder='R$ 0,00'
                                        value={values.teto}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete="off" />
                                    <MessageError error={errors.teto} />
                                </div>
                                <div className='row col-md-12 button-right mt-4'>
                                    <button type='button' className='btn btn-outline-dark mr-3 btn-sm' onClick={onCancelar}>
                                        <i className="fas fa-times" />
                                        Cancelar
                                        </button>
                                    <button type='button' className='btn btn-outline-dark btn-sm mr-3' onClick={handleReset}>
                                        <i className="fas fa-eraser" />
                                            Limpar
                                        </button>
                                    <If test={getPermissaoCadastrar() || isEditar}>
                                        <button type="submit" className='btn btn-info btn-sm'>
                                            <i className={['fas', isEditar ? 'fa-save' : 'fa-plus'].join(" ")} />
                                            {isEditar ? 'Salvar' : 'Adicionar'}
                                        </button>
                                    </If>
                                </div>
                            </div>
                        </form>
                    </Painel>
                </div>
            </Secured>
        </Fragment>
    )
}

export default CadastroSalarioMinimoTeto;