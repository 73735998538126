import { useToken } from '../Security';

const useUsuarioLogado = () => {
  const { tokenParsed } = useToken();
  return {
    userName: tokenParsed.preferred_username,
    nome: tokenParsed.name,
  };
};

export default useUsuarioLogado;
