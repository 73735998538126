import React from "react";
import { Secured } from "../../../component";
import { Permissions } from "../../../Constantes/permissions.constants";

import {
  Card,
  Tabs,
} from "antd";
import IndiceInpc from "./inpc";
import IndiceIpca from "./ipca";

const IndiceCorrecaoAposentadoria = () => {
  
  return (
    <>
      <Secured
        hasAnyRole={Permissions.INDICE_CONTRIBUICAO.INDICE_CONTRIBUICAO}
        isShowErro403={true}
      >
        <h1>Índices de Correção de Contribuição</h1>
        <Card title="Consultar Correções de Contribuições">
        <Tabs 
          defaultActiveKey="1" 
          items={[
            {
              label: "INPC",
              key: "1",
              children: <IndiceInpc />
            },
            {
              label: "IPCA",
              key: "2",
              children: <IndiceIpca />
            }
          ]} >
          
        </Tabs>
          
        </Card>
      </Secured>
    </>
  );
};

export default IndiceCorrecaoAposentadoria;
