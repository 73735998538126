import React, { useState, useEffect, useContext } from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import useForm from '../../../helper/useForm';
import If from 'gp-if';
import CPF from 'gp-cpf';
import { Message } from '../../../Constantes/message.constants';
import services from './../services';
import MessageContext from '../../../state/Message/Context';
import * as MessageActions from "../../../state/Message/actions";
import { Secured } from '../../../component';
import { MASK_NUMBER } from '../../../helper/mask';
import { MASK_CPF } from '../../../helper/mask';

import './styles.scss';
import MessageError from 'gp-message-error';
import { Permissions } from '../../../Constantes/permissions.constants';
import useSecurityContext from '../../../component/Security/useSecurityContext';
import MainHomeContext from '../../../state/Home/Context';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { getURL } from '../../../helper/util';

const CadastroVinculoInstituidorDependente = ({ match }) => {

  const { messageDispatch } = useContext(MessageContext);
  const { hasPermission } = useSecurityContext();
  const { mainHome } = useContext(MainHomeContext);
  let history = useHistory();
  let { id } = match.params;
  const { state } = useLocation();

  const initialValuesSchema = {
    cpf: '',
    nome: '',
    nascimento: '',
    processo: '',
    finalidade: '',
    interessado: ''
  }

  const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
  const [infoProcesso, setInfoProcesso] = useState({ cpf: '', nome: '', nascimento: '' });
  const [isEditar, setIsEditar] = useState(false);

  const validationForm = {
    processo: {
      required: true
    },
    finalidade: {
      required: true
    },
    interessado: {
      required: true
    }
  }

  useEffect(() => {
    let processo = {};
    if (id) {
      services.obter(id).then((response) => {
        if (response) {
          setIsEditar(true);
          processo = {
            processo: response.processo,
            finalidade: response.finalidade,
            interessado: response.interessado,
            pessoaId: response.pessoa.id
          }
          setValuesSchema(processo);
        }
      }).catch(console.log);
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      let mask = MASK_CPF;
      mask = mask.filter((val) => val !== '[]');
      let retorno = conformToMask(state.cpf, mask, { guide: false });
      state.cpf = retorno.conformedValue;
      setInfoProcesso(state);
    } else {
      voltar();
    }
  }, [state]);


  const inserir = () => {
    services.inserir(formatarRequest()).then(resp => {
      messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_PROCESSO_SUCESSO]));
      voltar();
    }).catch(error => console.log(error));
  }

  const onSubmit = () => {
    if (isEditar) {
      onEditar();
    } else {
      inserir();
    }
  }

  useEffect(() => {
    handleUpdateValues();
  }, [valuesSchema]); // eslint-disable-line

  const { values, errors, handleChange, handleBlur, handleSubmit, handleUpdateValues, handleReset } =
    useForm(valuesSchema, onSubmit, validationForm);

  const onEditar = () => {
    let edicao = formatarRequest();
    edicao.id = id;
    services.editar(edicao).then(resp => {
      messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_PROCESSO_EDICAO_SUCESSO]));
      voltar();
    }).catch(error => console.log(error));
  }

  const formatarRequest = () => {
    return {
      pessoa: { id: isEditar ? values.pessoaId : state.pessoaId },
      processo: values.processo,
      finalidade: values.finalidade,
      interessado: values.interessado
    }
  }

  const onCancelar = () => {
    voltar();
  }

  const voltar = () => {
    history.push({
      pathname: getURL("/processo", 'processo', mainHome),
      state: state
    });
  }

  const getPermissaoCadastrar = () => hasPermission({ hasAnyRole: Permissions.VINCULO_INSTI_PENSIONISTA.BOTAO_CADASTRAR_PROCESSO });

  return (
    <>
      <Secured hasAnyRole={Permissions.VINCULO_INSTI_PENSIONISTA.CADASTRAR_PROCESSO} isShowErro403={true}>
        <div className='col-12'>
          <Painel titulo='Processos de Vínculo Instituidor-Pensionista'>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-7 col-lg-3 col-xl-2 input-group-sm">
                  <CPF
                    id='cpf'
                    label='CPF'
                    values={infoProcesso}
                    errors={errors}
                    disabled={true}
                  />

                </div>
                <div className='form-group col-12 col-lg-9 col-xl-7  input-group-sm'>
                  <label htmlFor='nome' className='col-form-label'>Nome do Segurado</label>
                  <input id='nome' name='nome' className='form-control' value={infoProcesso.nome} disabled />
                </div>
                <div className='form-group col-md-6 col-lg-6 col-xl-3 input-group-sm'>
                  <label htmlFor='dataNascimento' className='col-form-label'>Data de Nascimento</label>
                  <input id='dataNascimento' name='dataNascimento' className='form-control' value={infoProcesso.nascimento} disabled />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-5 col-lg-3 col-xl-2 input-group-sm processo'>
                  <label htmlFor='processo' className='col-form-label'>Processo</label>
                  <MaskedInput id="processo" name="processo"
                    className={`form-control ${errors.processo && "is-invalid"}`}
                    guide={false}
                    mask={MASK_NUMBER()}
                    onChange={handleChange}
                    value={values.processo}
                    maxLength='15'
                    onBlur={handleBlur}
                    autoComplete="off" />
                  <MessageError error={errors.processo} />
                </div>
                <div className='form-group col-md-5 col-lg-9 col-xl-4 input-group-sm'>
                  <label htmlFor='finalidade' className='col-form-label'>Finalidade</label>
                  <input
                    id='finalidade'
                    name='finalidade'
                    className={`form-control ${errors.finalidade && "is-invalid"}`}
                    onBlur={handleBlur}
                    value={values.finalidade}
                    onChange={handleChange} />
                  <MessageError error={errors.finalidade} />
                </div>
                <div className='form-group col-md-5 col-lg-12 col-xl-6 input-group-sm'>
                  <label htmlFor='interessado' className='col-form-label'>Interessado(s)</label>
                  <input
                    id='interessado'
                    name='interessado'
                    className={`form-control ${errors.interessado && "is-invalid"}`}
                    value={values.interessado}
                    onBlur={handleBlur}
                    onChange={handleChange} />
                  <MessageError error={errors.interessado} />
                </div>

                <div className='row col-md-12 button-right mt-4 ml-1 mb-4'>
                  <button type='button' className='btn btn-outline-dark mr-3 btn-sm' onClick={onCancelar}>
                    <i className="fas fa-times" />Cancelar
                  </button>
                  <button type='button' className='btn btn-outline-dark btn-sm mr-3' onClick={handleReset}>
                    <i className="fas fa-eraser" />
                      Limpar
                  </button>
                  <If test={getPermissaoCadastrar() || isEditar}>
                    <button type="submit" className='btn btn-info btn-sm'>
                      <i className={['fas', isEditar ? 'fa-save' : 'fa-plus'].join(" ")} />
                      {isEditar ? 'Salvar' : 'Adicionar'}
                    </button>
                  </If>
                </div>
              </div>
            </form>
          </Painel>
        </div>
      </Secured>
    </>
  )
}

export default CadastroVinculoInstituidorDependente;