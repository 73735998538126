import axios from 'axios';
import config from '../../config';


const BASE_API = config.BASE_API;

const obterCPF = (cpf) => {
    return axios.get(BASE_API + '/funcional/' + cpf + '?instituidor=true');
}

const obterPensao = (idVinculo, params) => {
    return axios.put(BASE_API + '/pensoes/' + idVinculo, params);
}

const obterProcessos = (params) => {
    return axios.get(BASE_API + '/processos', { params }).then((resp) => resp.data);
}

const imprimirCalculoPensao = (idVinculo, params) => {
    axios
        .put(BASE_API + '/relatorios/pensao/' + idVinculo, params, { responseType: 'blob' })
        .then((resp) => resp ? resp.data : resp)
        .then((data) => {
            const url = URL.createObjectURL(data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.target = '_blank';
            a.click();
        });
};

const obterDependencia = () => {
    return [
        {
            codigo: 0,
            nome: 'Dependencia Presumida'
        },
        {
            codigo: 1,
            nome: 'Dependencia Economica'
        },
        {
            codigo: 2,
            nome: 'Invalidez'
        }
    ]
}

export default {
    obterCPF,
    obterPensao,
    obterDependencia,
    imprimirCalculoPensao,
    obterProcessos
}
