import axios from "axios";
import React from "react";
import { useToken } from "../component";
import { Message } from "../Constantes/message.constants";
import * as LoadingActions from "../state/Loading/actions";
import LoadingContext from "../state/Loading/Context";
import * as MessageActions from "../state/Message/actions";
import MessageContext from "../state/Message/Context";
import { notification } from "antd";

const options = {};

const requestConfig = (config) => {
  const { loadingDispatch, token } = options;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  if (loadingDispatch) loadingDispatch(LoadingActions.addLoading(true));
  return config;
};

const responseConfig = (response) => {
  const { loadingDispatch } = options;
  if (loadingDispatch) loadingDispatch(LoadingActions.removeLoading);
  return response;
};

const responseErrorHandler = (error) => {
  const { loadingDispatch, messageDispatch, history } = options;
  if (loadingDispatch) loadingDispatch(LoadingActions.removeLoading);
  if (error.response) {
    const status = error.response.status;

    if (status === 403) {
      history.push("/acesso-negado");
    } else if (status == 405) {
      // eslint-disable-line
      if (messageDispatch) {
        notification.error({
          message: "Ops!",
          description: error.response.data,
        });
        // messageDispatch(MessageActions.addErrorMessage([error.response.data]));
      }
    } else if (status >= 500 && status <= 599) {
      if (messageDispatch) {
        notification.error({
          message: "Ops!",
          description: error.response.data
            ? error.response.data
            : Message.OCORREU_ERRO_ACAO,
        });
      }
    }
    return Promise.reject(error);
  }
};

axios.interceptors.request.use(requestConfig, (error) => Promise.reject(error));

axios.interceptors.response.use(responseConfig, responseErrorHandler);

export default {
  InterceptorAxios: (history) => {
    const { loadingDispatch } = React.useContext(LoadingContext);
    const { messageDispatch } = React.useContext(MessageContext);
    const { token } = useToken();

    Object.assign(options, {
      loadingDispatch,
      messageDispatch,
      token,
      history,
    });
  },
};
