import React from "react";
import { Card, Row, Col, Skeleton } from "antd";
import { CalendarOutlined, HistoryOutlined } from "@ant-design/icons";

function RegrasAplicaveis(props) {
  const { regrasAplicaveis, loading } = props;

  return (
    <>
      {regrasAplicaveis && (
        <Card title="Regras Aplicáveis">
          <Row gutter={[10, 10]} className={"grider"}>
            {regrasAplicaveis.map((regra, index) => (
              <>
                <Col xs={24} sm={24} md={12} lg={8} key={index}>
                  <Card
                    key={index}
                    style={{ minHeight: "266px", height: "100%", flex: 1 }}
                    loading={loading}
                  >
                    {regra.regra && (
                      <Row>
                        <Col>
                          <p className="regrasAplicaveisTitulo">
                            {regra.regra}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {regra.data && (
                      <Row>
                        <Col style={{ display: "flex", marginTop: "6px" }}>
                          <HistoryOutlined
                            className="icon"
                            style={{ fontSize: "23px" }}
                          />
                          <p className="align p-info">{regra.data}</p>
                        </Col>
                      </Row>
                    )}
                    {regra.idade && (
                      <Row>
                        <Col style={{ display: "flex", marginTop: "6px" }}>
                          <CalendarOutlined
                            className="icon"
                            style={{ fontSize: "23px" }}
                          />
                          <p className="align p-info">Aos {regra.idade} anos</p>
                        </Col>
                      </Row>
                    )}
                    {regra.beneficio && (
                      <Row>
                        <Col style={{ display: "flex", marginTop: "6px" }}>
                          <p className="font">{regra.beneficio}</p>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Col>
              </>
            ))}

            <Col span={24}>
              {loading ? (
                <Skeleton active />
              ) : (
                <p className="text-secondary mt-2">
                  Este resultado é apenas uma simulação e não possui validade
                  jurídica, estando sujeita a validação dos dados pela Gestão de
                  Pessoas do órgão de origem. Regras aplicáveis apenas à
                  segurados regidos pelo Regime Próprio de Previdência Social -
                  RPPS e Sistema de Proteção Social dos Militares - SPSM do
                  Estado de Goiás.
                </p>
              )}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}

export default RegrasAplicaveis;
