import { AlignType } from '../../component/TableDemostrativa/alignType';
import Column from '../../component/TableDemostrativa/column';

export const configTableDependentes = () => {
    const configTables = [
        [{
            name: 'Dependente Beneficiário',
        },
        {
            name: 'Parentesco',
            align: AlignType.CENTER
        },
        {
            name: 'Nascimento',
            align: AlignType.CENTER
        },
        {
            name: '%',
            align: AlignType.CENTER
        },
        {
            name: 'Valor das Cotas',
            align: AlignType.CENTER
        },
        {
            name: 'Ações',
            align: AlignType.CENTER
        }]
    ]

    const columns = new Column();

    return columns.addColumn(configTables);

}