import React, { useState, useEffect, useContext, useMemo } from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css'
import useForm from '../../../helper/useForm';
import If from 'gp-if';
import Table, {TypeFilter, TypeOperator, AlignType} from 'gp-table';
import 'gp-table/dist/index.css';

import CPF from 'gp-cpf';
import { validateCpf } from '../../../helper/validation';
import { Message } from '../../../Constantes/message.constants';
import services from './../services';
import MessageContext from '../../../state/Message/Context';
import * as MessageActions from "../../../state/Message/actions";
import { Secured } from '../../../component';
import { removeMask } from '../../../helper/mask';

import { Permissions } from '../../../Constantes/permissions.constants';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import useSecurityContext from '../../../component/Security/useSecurityContext';
import config from '../../../config';

const VinculoInstituidorDependente = () => {

  const { messageDispatch } = useContext(MessageContext);
  const { hasPermission } = useSecurityContext();
  let history = useHistory();
  let { path } = useRouteMatch();
  const { state } = useLocation();
  const urlCadastro = `${path}/cadastro`;
  const urlEdicao = `${path}/edicao`;

  const initialValuesSchema = {
    cpf: '',
    nome: '',
    nascimento: '',
  }

  const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
  const [isHasCPF, setIsHasCPF] = useState(false);
  const [filtro, setFiltro] = useState({});
  const [resetTable, setResetTable] = useState(false);

  const validationForm = {
    cpf: {
      required: true,
      validator: [
        {
          tipo: validateCpf,
          error: Message.MSG002_CPF_INVALIDO,
        },
      ],
    },
  }

  const cancelar = () => {
    setValuesSchema(initialValuesSchema);
    setIsHasCPF(false);
    setFiltro({});
    setResetTable(true);
  }

  useEffect(() => {
    if (state) {
      setValuesSchema(state);
      setIsHasCPF(true);
      setFiltro({
        field: 'pessoa.id',
        value: state.pessoaId,
        fieldType: 'number',
        operator: 'EQ'
      });
    }
  }, [state]);

  const onBlur = (event) => {
    if (event.target.name === 'cpf') {
      const cpf = removeMask(values.cpf);

      if (cpf && cpf.length === 11) {
        services.obterCPF(cpf).then((resp) => {

          setValuesSchema(resp);
          setFiltro({
            field: 'pessoa.id',
            value: resp.pessoaId,
            fieldType: 'number',
            operator: 'EQ'
          });
          setIsHasCPF(true);
        }).catch(error => console.log(error));


      } else if (valuesSchema.nome !== '') {
        initialValuesSchema.cpf = values.cpf;
        setValuesSchema(initialValuesSchema);
      }
    }
  }


  const onSubmit = () => { }

  const { values, errors, handleChange, handleBlur, handleUpdateValues } =
    useForm(valuesSchema, onSubmit, validationForm, cancelar, onBlur);

  const onExcluir = async (id, obterLista, initialPage, pageSize, url, sortBy) => {
    services.excluir(id).then(resp => {
      messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_PROCESSO_EXCLUSAO_SUCESSO]));
      if (obterLista) {
        obterLista(initialPage, pageSize, url, sortBy, [], true);
      }
    }).catch(error => console.log(error));
  }

  const editar = (event, item) => {
    if (event) {
      event.preventDefault();
    }

    irParaEditar(item);
  }

  const onCadastrar = () => {
    history.push({
      pathname: urlCadastro,
      state: {
        cpf: values.cpf,
        nascimento: values.nascimento,
        nome: values.nome,
        pessoaId: values.pessoaId,
      }
    });
  }

  const irParaEditar = (id) => {
    history.push({
      pathname: urlEdicao + '/' + id,
      state: {
        cpf: state ? state.cpf : values.cpf,
        nascimento: state ? state.nascimento : values.nascimento,
        nome: state ? state.nome : values.nome,
        pessoaId: state ? state.pessoaId : values.pessoaId
      }
    });
  }

  const columns = useMemo(() =>
    [
      {
        Header: "Processo",
        accessor: "processo",
        filter: {
          tipo: TypeFilter.NUMBER,
          nome: "Processo",
          minLength: 7,
          operator: TypeOperator.IGUAL,
          field: "processo",
        },
        alignItems: AlignType.CENTER,
      },
      {
        Header: "Finalidade",
        accessor: "finalidade",
        filter: {
          tipo: TypeFilter.TEXT,
          nome: "Finalidade",
          minLength: 1,
          operator: TypeOperator.IGUAL,
          field: 'finalidade',
        },
        alignItems: AlignType.CENTER
      },
      {
        Header: "Interessado(s)",
        accessor: "interessado",
        filter: {
          tipo: TypeFilter.TEXT,
          nome: "Interessado(s)",
          minLength: 1,
          operator: TypeOperator.IGUAL,
          field: 'Interessado(s)',
        },
        alignItems: AlignType.CENTER
      },
    ], []
  )

  useEffect(() => {
    handleUpdateValues();
  }, [valuesSchema]); // eslint-disable-line

  return (
    <>
      <Secured hasAnyRole={Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_CPF_SEGURADO} isShowErro403={true}>
        <div className='col-12'>
          <Painel titulo='Processos de Vínculo Instituidor-Pensionista'>
            <form>
              <div className="form-row">
                <div className="form-group col-7 col-lg-3 col-xl-2 input-group-sm">
                  <CPF
                    id='cpf'
                    label='CPF'
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    disabled={isHasCPF}
                    errors={errors}
                  />

                </div>
                <div className='form-group col-12 col-lg-9 col-xl-7  input-group-sm'>
                  <label htmlFor='nome' className='col-form-label'>Nome do Segurado</label>
                  <input id='nome' name='nome' className='form-control' value={values.nome} disabled />
                </div>
                <div className='form-group col-md-6 col-lg-6 col-xl-3 input-group-sm'>
                  <label htmlFor='dataNascimento' className='col-form-label'>Data de Nascimento</label>
                  <input id='dataNascimento' name='dataNascimento' className='form-control' value={values.nascimento} disabled />
                </div>
              </div>
              <div className='row col-md-12 button-right mb-4 pr-0'>
                <button type='button' onClick={cancelar}
                  className="btn btn-outline-dark btn-sm mr-3" >
                  <i className="fa fa-eraser fa-fw" />Limpar
                </button>
                <Secured hasAnyRole={Permissions.VINCULO_INSTI_PENSIONISTA.BOTAO_CADASTRAR_PROCESSO}>
                  <button type='button' onClick={onCadastrar}
                    disabled={!isHasCPF}
                    className="btn btn-info btn-sm" >
                    <i className="fa fa-plus fa-fw" /> Novo
                    </button>
                </Secured>
              </div>
              <If test={isHasCPF}>
                <Secured hasAnyRole={Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_PROCESSO_VINCULADO}>
                  <Table
                    columns={columns}
                    hasPermissionsAction={Permissions.VINCULO_INSTI_PENSIONISTA.ACOES_TABELA}
                    hasPermissionEditar={Permissions.VINCULO_INSTI_PENSIONISTA.EDITAR_PROCESSO}
                    hasPermissionExcluir={Permissions.VINCULO_INSTI_PENSIONISTA.EXCLUIR_PROCESSO}
                    url={config.BASE_API + '/processos/filter/'}
                    editAction={editar}
                    isFilterTable={true}
                    deleteAction={onExcluir}
                    filtroLista={filtro}
                    isFormFilter={false}
                    resetTable={resetTable}
                    message={Message}
                    hasPermission={hasPermission}
                  />
                </Secured>
              </If>
            </form>

          </Painel>
        </div>
      </Secured>
    </>
  )
}

export default VinculoInstituidorDependente;