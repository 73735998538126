import React from 'react'
import { Secured } from '../../component';
import Usuario from 'gp-usuarios';
import { Permissions } from '../../Constantes/permissions.constants';
import useForm from '../../helper/useForm';
import config from '../../config';
import 'gp-usuarios/dist/index.css';

const AdministracaoUsuario = () => {

    return (
        <>
            <Secured hasAllRole={Permissions.ADMINISTRACAO_SISTEMA.ADMIN_USUARIO} isShowErro403={true}>
                <Usuario sistema="Simulador" isLocalizacao={true} useForm={useForm} urlSaApi={config.SA_API}></Usuario>
            </Secured>
        </>
    )
}

export default AdministracaoUsuario;