import React, { useEffect, useState } from 'react';
import MessageError from 'gp-message-error';
import Modal from 'gp-modal';
import useForm from '../../helper/useForm';
import Services from './services';

const ModalImprimir = ({ show, closeModal, dados, vinculoId }) => {

    const initValuesSchema = {
        observacao: '',
    }

    const [valuesSchema, setValuesSchema] = useState(initValuesSchema);

    useEffect(() => {
        setValuesSchema(initValuesSchema);
    }, [show]);  // eslint-disable-line

    const validationForm = {
        observacao: {
            required: true
        },
    };

    const imprimir = () => {
        dados.observacao = values.observacao;
        Services.imprimirCalculoPensao(vinculoId, dados);
        closeModal();
    }

    const { values, errors, handleChange, handleBlur, handleSubmit, handleUpdateValues } = useForm(valuesSchema, imprimir, validationForm);

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    return (
        <Modal title='Imprimir' showModal={show} closeModal={closeModal}>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className="form-group col-12 input-group-sm">
                            <label className="col-form-label" htmlFor="observacao">
                                Observação
                            </label>
                            <textarea
                                className={`form-control ${errors.observacao && "is-invalid"}`}
                                id="observacao"
                                name="observacao"
                                type="text"
                                rows="4"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.observacao}
                            />
                            <MessageError error={errors.observacao} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" onClick={closeModal}>
                            <i className='fas fa-times'></i>
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-outline-primary">
                            <i className='fas fa-print'></i>
                            Imprimir
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ModalImprimir;
