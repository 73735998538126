import React from "react";
import "./App.less";
import "./App.scss";
import { ErrorBoundary, SecurityProvider } from "./component";
import config from "./config";
import Home from "./page/Home";
import MainHomeProvider from "./state/Home/Provider";
import LoadingProvider from "./state/Loading/Provider";
import MenuProvider from "./state/Menu/Provider";
import MessageProvider from "./state/Message/Provider";
import { ConfigProvider } from "antd";
import pt_BR from "antd/lib/locale/pt_BR";

const securityConfig = {
  realm: "goiasprev",
  clientId: "simulador",
  url: config.KEYCLOAK_AUTH,
};
const App = () => {
  const isHomolog = () => {
    if (process.env.REACT_APP_ENV === "homolog") {
      return true;
    }
    return false;
  };

  return (
    <div id="app" className={isHomolog() ? "light-theme" : "dark-theme"}>
      <ErrorBoundary>
        <ConfigProvider
          locale={pt_BR}
          theme={{
            token: {
              colorPrimary: "#5a4f0a",
              colorSuccess: "#12481a",
              colorWarning: "#faad14",
              colorError: "#ff0000",
              colorInfo: "#0000ff",
            },
          }}
        >
          <SecurityProvider config={securityConfig} loginRequired>
            <LoadingProvider>
              <MenuProvider>
                <MessageProvider>
                  <MainHomeProvider>
                    <Home />
                  </MainHomeProvider>
                </MessageProvider>
              </MenuProvider>
            </LoadingProvider>
          </SecurityProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </div>
  );
};

export default App;
