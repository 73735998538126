import { intervalToDuration, getYear } from "date-fns";
import moment from "moment";
import { applyMaskAnoMesDia } from "./mask";

export const isEmpty = (value) => {
  if (value === null || value === undefined) return true;
  return value.length === 0;
};

export const isNotEmpty = (value) => {
  return !isEmpty(value);
};

export const blankValue = (value) => {
  return isNotEmpty(value) ? value : "";
};

export const nullValue = (valor) => {
  return isNotEmpty(valor) ? valor : null;
};

export const copyObject = (item) => Object.assign({}, item);

export const calcularDiferenciaEmAnosMesDias = (dataInicial, dataFinal) => {
  return intervalToDuration({
    start: dataInicial,
    end: dataFinal,
  });
};

export const getURL = (url, tipo, mainHome) => {
  return mainHome === tipo ? "/" : url;
};

export const getYearsPeriod = (date) => {
  const fimPeriodo = getYear(date);
  const inicioPeriodo = fimPeriodo - 11;
  return { inicioPeriodo, fimPeriodo };
};

export const getDateOfddMMYYYY = (dateString) => {
  let data = applyMaskAnoMesDia(dateString);
  return new Date(data + " 00:00:00");
};

export const getDateOfYYYYMMdd = (dateString) => {
  return new Date(dateString + " 00:00:00");
};

export const getDateOfYYYYMMddString = (dateString) => {
  if (dateString != null)
    return new Date(dateString + " 00:00:00").toLocaleDateString("pt-BR");
  return "";
};

export const imprimirRelatorio = (data) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.target = "_blank";
  a.click();
};

export function formatDate(dataString, formatter) {
  if (dataString != null) return moment(dataString).format(formatter);
  else return "";
}

export function dataAtualAnoMesDia(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

//Exemplo "01/11/1993" para "1993-11-01"
export function transformarDataStringComTraco(data) {
  if (data) {
    var partes = data.split("/");
    return partes[2] + "-" + partes[1] + "-" + partes[0];
  }
}

//Exemplo "01/11/1993" para "01111993"
export function transformarDataStringEmNumeroInteiro(data) {
  if (data) {
    var partes = data.split("/");
    return partes[0] + partes[1] + partes[2];
  }
}
//Exemplo "1993-11-01" para "01/11/1993"
export function transformarDataStringComBarra(data) {
  if (data) {
    var partes = data.split("-");
    return partes[2] + "/" + partes[1] + "/" + partes[0];
  }
}

export function convertMoment(pDate, pFormat) {
  if (pDate != null) return moment(pDate, pFormat);
  else return null;
}

export const returnStringAction = (action) => {
  switch (action) {
    case "post":
      return "Criar";
    case "put":
      return "Editar";
    case "delete":
      return "Excluir";

    default:
      break;
  }
};

export const verificarMesAnoValido = (mesAnoString) => {
  // Verificar se a string tem 7 caracteres (2 para o mês e 4 para o ano) e se contém apenas números
  if (mesAnoString.match(/^\d{2}\/\d{4}$/)) {
    const mes = parseInt(mesAnoString.substr(0, 2));
    const ano = parseInt(mesAnoString.substr(3, 4));
    // Verificar se o mês e o ano são válidos
    if (mes >= 1 && mes <= 12 && ano >= 1900 && ano <= 9999) {
      return true;
    }
  }

  return false;
};

export const converterDataParaAnoMesDia = (data) => {
  if (data) {
    var partes = data.split("/");
    return partes[2] + "-" + partes[1] + "-" + partes[0];
  }
};

export const convertObjectNewDateToStr = () => {
  const day = new Date().getDate().toString().padStart(2, "0");
  const month = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const year = new Date().getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const isValidDate = (dateString, format) => {
  // Verifica se a string tem o comprimento correto
  if (dateString && format === "##/##/####") {
    if (dateString && dateString.length < 10) {
      return false;
    }

    // Verifica se a string contém apenas dígitos e a barra "/"
    if (dateString && !/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Cria um objeto Date a partir da string
    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);
    var dateObject = new Date(year, month - 1, day);

    // Verifica se a data é válida
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  }

  if (dateString && format === "##/####") {
    if (!/^\d{2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Extrai o mês e o ano da data
    const [mes, ano] = dateString.split("/");

    // Verifica se o mês é válido (deve estar entre 1 e 12)
    if (parseInt(mes, 10) < 1 || parseInt(mes, 10) > 12) {
      return false;
    }

    // Verifica se o ano é válido (deve estar entre 1900 e o ano atual)

    if (parseInt(ano, 10) < 1900 || parseInt(ano, 10) > 9999) {
      return false;
    }

    // A data é válida
    return true;
  }
};
