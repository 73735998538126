import { AlignType } from '../../component/TableDemostrativa/alignType';
import Column from '../../component/TableDemostrativa/column';

export const configTable = (beneficios) => {
    let configTables = [
        [{
            name: 'Rubricas Último Vencimento',
            align: AlignType.LEFT
        },
        {
            name: 'Incorpora',
            align: AlignType.CENTER
        },
        {
            name: 'Valor',
            align: AlignType.CENTER
        },
        {
            name: 'Ações',
            align: AlignType.CENTER
        }]
    ];

    if (beneficios.descontoDescricao !== ''
        && beneficios.descontoReferencia !== ''
        && beneficios.descontoValor !== ''
        && beneficios.descontoReferencia) {
        configTables.push(
            [{
                name: 'Desconto Teto RGPS',
                align: AlignType.LEFT
            },
            {
                name: 'Referência',
                align: AlignType.CENTER
            },
            {
                name: 'Desconto',
                align: AlignType.CENTER
            },
            {
                name: '',
                align: AlignType.CENTER
            }]);
    }

    if (beneficios.reajustes.length > 0) {
        configTables.push([{
            name: 'Reajuste',
            align: AlignType.LEFT
        },
        {
            name: 'Índice',
            align: AlignType.CENTER
        },
        {
            name: 'Reajuste',
            align: AlignType.CENTER
        },
        {
            name: '',
            align: AlignType.CENTER
        }]);
    }

    const columns = new Column();

    return columns.addColumn(configTables);

}