import React, { Fragment } from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';

import './styles.scss';

const Erro403 = ({ fallback }) => {
    return (
        <Fragment>
            <Painel classPainel='row justify-content-md-center painel-erro' classConteudo='col-lg-3'>
                <div className='row justify-content-md-center text-center conteudo'>
                    <i className='fas fa-user-lock fa-5x' />
                    <div className='col-md-12 mt-4'>
                        <span className='title-erro'>Erro 403</span>
                    </div>
                    <p className='col-md-12'>{fallback ? fallback : 'Usuário sem permissão de acesso à funcionalidade.'}</p>
                    {/* <hr className='col-md-12' /> */}
                    {/* <p className='font-weight-bold'>Acesse outra funcionalidade</p>
                    
                    <div className="funcionalidades-itens col-md-12">

                        <div className="funcionalidades-item">
                            <a href="/#">
                                <span className="funcionalidades-item-icon"><i className="fas fa-hand-holding-usd"/></span>
                                <span className="funcionalidades-item-title">Arrecadação</span>
                            </a>
                        </div>

                        <div className="funcionalidades-item">
                            <a href="/#">
                                <span className="funcionalidades-item-icon"><i className="fas fa-id-card"/></span>
                                <span className="funcionalidades-item-title">Cadastro</span>
                            </a>
                        </div>

                        <div className="funcionalidades-item">
                            <a href="/#">
                                <span className="funcionalidades-item-icon"><i className="fas fa-calculator"/></span>
                                <span className="funcionalidades-item-title">Simulação</span>
                            </a>
                        </div>
                    </div> */}
                </div>
            </Painel>
        </Fragment>
    )
}

export default Erro403;