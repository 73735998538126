import { useState, useCallback } from "react";

// import MessageContext from "../state/Message/Context";
// import Occurrence from "../state/Message/Occurrence";
// import * as OccurrenceType from "../state/Message/occurrence-type";
// import * as OccurrenceIcon from "../state/Message/occurrence-icon";
// import * as MessageActions from "../state/Message/actions";

import { Message } from "../Constantes/message.constants";

const useForm = (valuesSchema, callback, validationForm = {}, callbackCancelar, callbackBlur) => {
    // const {messageDispatch}   = useContext(MessageContext);
    const [values, setValues] = useState(valuesSchema);
    const [errors, setErrors] = useState([]);

    const callBack = useCallback(() => { callback() }, [callback]);

    const blur = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        const validationField = validationForm[name];
        let hasError = false;

        if (validationField && validationField.required && !value) {
            addError(name, Message.CAMPO_OBRIGATORIO);
            hasError = true;
        } else if (validationField && validationField.validator !== undefined && validationField.validator.length !== 0) {
            hasError = verificarHasValidadores(validationField.validator, name, value);
        } else {
            setErrors(errors => ({ ...errors, [name]: null }));
            hasError = false;
        }
        return hasError;
    }

    const handleBlur = event => {

        const hasError = blur(event);

        if (callbackBlur && !hasError) {
            callbackBlur(event);
        }
    };

    const handleBlurWithoutCallback = (event) => {
        blur(event);
    }

    const validateValues = useCallback(() => {
        if (Object.keys(validationForm).length > 0) {

            const hasErrorForm = Object.keys(validationForm).map(item => {
                const fieldRequired = validationForm[item].required;
                const value = values[item];
                const validadores = validationForm[item].validator;

                if (fieldRequired && !value) {
                    setErrors(errors => ({ ...errors, [item]: Message.CAMPO_OBRIGATORIO }));
                    return true;
                } else if (validadores !== undefined && validadores.length !== 0) {
                    return verificarHasValidadores(validadores, item, value);
                } else {
                    setErrors(errors => ({ ...errors, [item]: null }));

                    return false;
                }

            });
            return !hasErrorForm.includes(true);
        } else {
            return true;
        }

    }, [values, validationForm]);  // eslint-disable-line

    const handleSubmit = event => {
        if (event) {
            event.preventDefault();
        }

        if (validateValues()) {
            callBack();
        } else {
            // let messages = [];
            // Object.keys(errors).map(item => {
            //          messages.push(errors[item]);
            //          return item;
            //     });
            //     let oc = new Occurrence(OccurrenceType.DANGER, "Ocorreram os seguintes erros:", messages, OccurrenceIcon.ERROR);
            //     messageDispatch(MessageActions.addMessage(oc));
        }

    };

    const handleChange = event => {

        if (event) {
            event.persist();
        }

        // event.preventDefault();

        const target = event.target;
        const name = target.name;
        const value = target.value;

        setValues(values => ({
            ...values,
            [name]: value
        }));
    };

    const handleChangeArray = event => {
        event.preventDefault();

        const target = event.target;
        const name = target.name.split('.');
        const value = target.value;

        const array = values[name[0]].map((item, index) => {
            if (index == name[1]) { // eslint-disable-line
                item[name[2]] = value;
            }
            return item;
        });

        setValues(values => ({
            ...values,
            [name[0]]: array
        }));
    }

    const verificarHasValidadores = (validator, name, value) => {
        if (Array.isArray(validator)) {
            let arrayIfErrors = [];
            validator.map(item => {
                const isValido = verificarValidador(item, name, value);
                arrayIfErrors.push(isValido);
                return item;
            });
            return arrayIfErrors.includes(true);

        } else {
            return verificarValidador(validator, name, value);
        }
    }

    const verificarValidador = (validator, name, value) => {
        if (validator.tipo !== undefined && !validator.tipo(value)) {
            setErrors(errors => ({ ...errors, [name]: validator.error }));
            return true;
        } else {
            setErrors(errors => ({ ...errors, [name]: null }));
            return false;
        }
    }

    const addError = (label, value) => {
        setErrors({
            ...errors,
            [label]: value
        });
    }

    const handleReset = event => {
        if (event) {
            event.preventDefault();
        }
        setValues(valuesSchema);
        setErrors([]);
        if (callbackCancelar) {
            callbackCancelar();
        }
    }
    const handleUpdateValues = () => {
        setValues({
            ...values,
            ...valuesSchema
        });
    }

    return {
        handleChange,
        handleSubmit,
        handleBlur,
        handleBlurWithoutCallback,
        handleReset,
        handleUpdateValues,
        handleChangeArray,
        values,
        errors,
        setErrors
    };
};

export default useForm;
