import React from "react";
import { Card, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./styles.scss";

const AddButtonCard = (props) => {
  const { handleAdd, minHeight, loading } = props;

  return (
    <Card
      onClick={() => {
        handleAdd();
      }}
      style={{ minHeight: minHeight }}
      className={"cardAdd"}
      loading={loading}
    >
      <Row className={"roww"}>
        <Col span={24}>
          <PlusOutlined className={"AddIcon"} />
        </Col>
      </Row>
    </Card>
  );
};

export default AddButtonCard;
