import axios from 'axios'
import config from '../../config';

const BASE_API = config.BASE_API;

const obterCPF = (cpf) => axios.get(BASE_API + '/funcional/' + cpf + '?instituidor=true').then((resp) => resp.data);

const inserir = (dados) => axios.post(BASE_API + '/processos', dados).then((resp) => resp.data);

const excluir = (id) => axios.delete(BASE_API + '/processos/' + id).then((resp) => resp.data);

const editar = (dados) => axios.put(BASE_API + '/processos/' + dados.id, dados).then((resp) => resp.data);

const obterProcessos = (params) => axios.get(BASE_API + '/processos', { params }).then((resp) => resp.data);

const obter = (id) => axios.get(BASE_API + '/processos/' + id).then((resp) => resp.data);


export default {
    inserir,
    obterProcessos,
    obterCPF,
    excluir,
    editar,
    obter
}