export const Message = {
    MSG001_CPF_NAO_ENCONTRADO: 'CPF não encontrado',
    MSG002_CPF_INVALIDO: 'Informe um CPF válido',
    MSG003_CPF_NAO_AUTORIZADO: 'CPF não autorizado ao cadastro de processos de vínculo instituidor-pensionista',
    MSG004_REFERENCIA_EXISTE: 'Referência já existe',
    MSG005_SEM_CONEXAO: 'Sem conexão com a internet. Tente Novamente',
    MSG006_CAMPO_NAO_INFORMADO: 'Campo não informado',
    MSG007_CPF_SENHA_INVALIDOS: 'CPF ou Senha inválidos',
    MSG008_CPF_NAO_HABILITADO: 'CPF não habilitado para a simulação',

    EMAIL_INVALIDO: 'Informe um email válido',
    CPF_OBRIGATORIO: 'CPF obrigatório',
    CAMPO_OBRIGATORIO: 'Campo obrigatório',
    PREENCHA_CAMPOS_OBRIGATORIOS: 'Preencha os campos obrigatórios.',
    PREENCHA_TIPO_VALOR: 'Preencha o tipo e o valor do benefício para adicionar',
    SOLICITACAO_INCORRETA_415: '415 - Solicitação Incorreta',
    SALARIO_MINIMO_TETO_SUCESSO: 'Salário Mínimo e Teto cadastrado com sucesso',
    SALARIO_MINIMO_TETO_EXCLUSAO_SUCESSO: 'Salário Mínimo e Teto excluído com sucesso',
    SALARIO_MINIMO_TETO_EDITADO_SUCESSO: 'Salário Mínimo e Teto editado com sucesso',
    INDICE_CORRECAO_APOSENTADORIA_SUCESSO: 'Índice de Correção de Aposentadoria cadastrado com sucesso',
    CADASTRO_INDICE_APOSENTADORIA_EDICAO_SUCESSO: 'Índice de Correção de Aposentadoria editado com sucesso',
    CADASTRO_INDICE_APOSENTADORIA_EXCLUSAO_SUCESSO: 'Índice de Correção de Aposentadoria excluído com sucesso',
    CADASTRO_INDICE_PENSAO_SUCESSO: 'Índice de Pensão cadastrado com sucesso',
    CADASTRO_INDICE_PENSAO_EDICAO_SUCESSO: 'Índice de Pensão editado com sucesso',
    CADASTRO_INDICE_PENSAO_EXCLUSAO_SUCESSO: 'Índice de Pensão excluído com sucesso',
    CADASTRO_PROCESSO_SUCESSO: 'Processos de Vínculo Instituidor-Pensionista cadastrado com sucesso',
    CADASTRO_PROCESSO_EDICAO_SUCESSO: 'Processos de Vínculo Instituidor-Pensionista editado com sucesso',
    CADASTRO_PROCESSO_EXCLUSAO_SUCESSO: 'Processos de Vínculo Instituidor-Pensionista excluído com sucesso',
    TEMPO_ADICIONAL_AVERBACAO_SUCESSO: 'Tempo adicional de averbação cadastrado com sucesso',
    DEPENDENTE_CALCULO_SUCESSO: 'Dependente para cálculo cadastrado com sucesso',
    CONFIRMACAO_EXCLUSAO: 'Deseja excluir este registro ?',
    CONFIRMACAO_REDIRECIONAR_BENEFICIO_ESPECIAL: 'O servidor tem a opção do Benefício Especial, deseja ir para a tela de Beneficio Especial?',
    TITLE_BENEFICIO_ESPECIAL: 'Benefício Especial',
    TITLE_CONFIRMACAO: 'Confirmação',
    OCORREU_ERRO_ACAO: 'Ocorreu erro ao executar a ação',
    PERMISSOES_ATUALIZADAS_SUCESSO: 'As permissões foram atualizadas com sucesso!',
    SELECIONE_UMA_PERMISSAO: 'Selecione pelo menos um grupo de permissões.',
    CADASTRO_USUARIO_REALIZADO_SUCESSO: 'Cadastro de usuário realizado com sucesso.',
    ATUALIZACAO_USUARIO_REALIZADO_SUCESSO: 'Atualização de usuário realizado com sucesso.',
    FALHA_BUSCA_USUARIO: 'Houve falha ao buscar o usuário, tente novamente!',
    FALHA_EDITAR_USUARIO: 'Houve falha ao editar o usuário, tente novamente!',
    FALHA_CADASTRAR_USUARIO: 'Houve falha ao cadastrar o usuário, tente novamente!',

}