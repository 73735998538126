import React from "react";
import { Secured } from "../../../component";
import { verificarMesAnoValido } from "../../../helper/util";
import { Permissions } from "../../../Constantes/permissions.constants";
import services from "./../services";
import { QueryFilter } from "@ant-design/pro-form";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import {
  Form,
  Button,
  Space,
  Row,
  Col,
  Card,
  Table,
  notification,
  Modal,
} from "antd";
import InputDate from "../../../component/InputDate";

const IndiceInpc = () => {
  const [data, setData] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({
    action: "",
    data: {},
  });
  const [render, setRender] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const pagination = {
    page: 0,
    size: 10,
  };

  const formatarDataMesAno = (data) => {
    if (data) {
      let dataStr = data.toString();
      const dataFormatada =
        dataStr.substring(4) + "/" + dataStr.substring(0, 4);

      return dataFormatada;
    }
  };

  const handleDelete = () => {
    setLoading(true);
    if (selectedData.data) {
      services
        .excluir(selectedData.data.id)
        .then(() => {
          notification.success({
            message: "Sucesso!",
            description: `Referência ${formatarDataMesAno(
              selectedData.data?.referencia
            )} foi excluída.`,
            duration: 5,
          });
          setRender(true);
          setOpenModal(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    form.resetFields();
  };

  React.useEffect(() => {
    setLoading(true);
    setRender(false);

    services
      .obterIndices({ pagination, searchQueries: [{}] })
      .then((resp) => setData(resp))
      .finally(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [render]);

  const getIndices = (parametros) => {
    setLoading(true);

    services
      .obterIndices(parametros)
      .then((resp) => setData(resp))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (filterValues) getIndices(filterValues);
  }, [filterValues]);

  const onChangeTable = function (pagination, filters, sorte) {
    var pagination = {
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: "",
    };
    pagination.sort =
      sorte.order != undefined
        ? sorte.columnKey + "," + sorte.order.substr(0, sorte.order.length - 3)
        : "";
    setFilterValues({
      ...filterValues,
      pagination,
    });
  };

  const onSearch = async (values) => {
    if (values.referencia) {
      const referenciaFormated = values.referencia.replace("/", "");
      const ano = referenciaFormated.substring(2);
      const mes = referenciaFormated.substring(0, 2);
      const dataAnoMes = ano + mes;
      values.referencia = dataAnoMes;
    }

    let searchQueries = [
      {
        field: "referencia",
        fieldType: "number",
        operator: "LK",
        value: values.referencia ? values.referencia : "",
      },
    ];

    setFilterValues({
      pagination,
      searchQueries: searchQueries,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        title: "Referência",
        dataIndex: "referencia",
        key: "referencia",
        sorter: true,
        render: (text) => formatarDataMesAno(text),
      },
      {
        title: "Percentual",
        dataIndex: "valor",
        key: "valor",
        sorter: true,
        render: (text) => `${text ? text.toString().replace(".", ",") : text}`,
      },

      {
        title: "Ações",
        width: "150px",
        render: (text) =>
          text.editar == true && (
            <Space size={10}>
              <Link to={`/contribuicao/edicao/${text.id}`}>
                <Button type="primary" icon={<EditOutlined />} />
              </Link>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  setOpenModal(true);
                  setSelectedData({
                    action: "delete",
                    data: text,
                  });
                }}
              />
            </Space>
          ),
      },
    ],
    []
  );

  return (
    <>
      <Secured
        hasAnyRole={Permissions.INDICE_CONTRIBUICAO.INDICE_CONTRIBUICAO}
        isShowErro403={true}
      >
        <Card title="Índices INPC">
          <QueryFilter
            layout="vertical"
            defaultCollapsed
            className="filtro"
            onFinish={onSearch}
            onReset={onSearch}
          >
            <Form.Item
              label="Referência"
              name="referencia"
              rules={[
                {
                  validator: async (info, value) => {
                    if (value && !verificarMesAnoValido(value)) {
                      await Promise.reject(new Error("Data inválida!"));
                    }
                  },
                },
              ]}
            >
              <InputDate format={"##/####"} placeholder="00/0000" />
            </Form.Item>
          </QueryFilter>
          <Secured hasAnyRole={Permissions.INDICE_CONTRIBUICAO.BOTAO_CADASTRO}>
            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  height: "50px",
                }}
              >
                <Link to="/contribuicao/cadastro">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedData({
                        action: "post",
                      });
                    }}
                  >
                    Criar
                  </Button>
                </Link>
              </Col>
            </Row>
          </Secured>
          <Secured
            hasAnyRole={
              Permissions.INDICE_CONTRIBUICAO.CONSULTAR_HISTORICO_INDICES
            }
          >
            <Table
              columns={columns}
              onChange={onChangeTable}
              dataSource={data?.content}
              pagination={{
                current: data?.pageable?.pageNumber
                  ? data?.pageable.pageNumber + 1
                  : 1,
                pageSize: data?.pageable?.pageSize,
                total: data?.totalElements,
                showSizeChanger: false,
                position: ["bottomCenter"],
              }}
              rowKey="id"
              loading={loading}
            />
          </Secured>
        </Card>

        <Form layout="vertical" form={form}>
          <Modal
            open={openModal}
            onCancel={() => handleCancel()}
            footer={[
              <Button onClick={() => handleCancel()}>Cancelar</Button>,
              <Button
                type="primary"
                loading={loading}
                onClick={() => handleDelete()}
              >
                Sim
              </Button>,
            ]}
          >
            <Row>
              <Col span={24} style={{ minWidth: "100%" }}>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <WarningOutlined style={{ fontSize: "100px" }} />
                  </div>
                  <p style={{ textAlign: "center" }}>
                    Deseja realmente <strong>Excluir ?</strong>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <strong>
                      Referência{" "}
                      {formatarDataMesAno(selectedData.data.referencia)}
                    </strong>
                  </p>
                </>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Secured>
    </>
  );
};

export default IndiceInpc;
