import axios from "axios";
import config from "../../config";
import { isEmpty } from "../../helper/util";

const BASE_API = config.BASE_API;

// const getBeneficio = (params) => axios.get(BASE_API + '/').then((resp) => resp.data);
const getBeneficio = (idVinculo, referencia) => {
  // let url = BASE_API + '' + idVinculo;
  // url = referencia !== undefined ? url + '?referencia=' + referencia : url;
  // return axios.post(url).then((resp) => resp.data);
};

// const getBeneficio = () => {
//     let teste = {
//         salarioAtual: 22000.00,
//         media: 15000.00,
//         tempoContribuicao: "15 anos",
//         aposentadoria: {
//             contribuicaoMensal: 3350.00,
//             valorMensalPrevistoAposentadoria: 15000.00,
//             valorMensalPrevistoPrevidenciario: 2175.00,
//             valorLiquidoMensalPrevistoAposentadoria: 12825.00
//         },
//         beneficioEspecial: {
//             valorMensalContribuicaoRestricaoTeto: 884.65,
//             valorMensalPrevistoAposentadoria: 6101.06,
//             valorMensalPrevistoContribuicaoPrevidenciaria: 3350.00,
//             valorMensalPrevisto: 3350.00,
//             valorLiquidoValorBeneficio: 3350.00
//         }
//     };
//     return Promise.resolve(teste);
// };

const getRelatorioBeneficioEspecial = (vinculo) => {
  let url = BASE_API + "/relatorios/beneficio/especial/" + vinculo;
  let referencia = undefined;
  let stopDate = undefined;
  
  return axios
    .post(url, undefined, {
      params: {
        referencia: referencia,
        simulacaoFinal: false,
        stopDate,
      },
      responseType: "blob",
    })
    .then((resp) => resp.data);
};

export default {
  getBeneficio, getRelatorioBeneficioEspecial
};
