import SimulacaoAtendente from "./page/SimulacaoAtendente";
import SimulacaoAposentadoria from "./page/SimulacaoAposentadoria";
import CadastroVinculoInstituidorDependente from "./page/VinculoInstituidorDependente/cadastro";
import CadastroSalarioMinimoTeto from "./page/SalarioMinimoTeto/cadastro";
import SimulacaoPensao from "./page/SimulacaoPensao";
import { Permissions } from "./Constantes/permissions.constants";
import AdministracaoUsuario from "./page/AdministracaoUsuario";
import CadastroIndiceCorrecaoBeneficio from "./page/IndiceCorrecaoBeneficio/cadastro";
import IndiceCorrecaoBeneficio from "./page/IndiceCorrecaoBeneficio/listagem";
import RouteChildren from "./RouteChildren";
import CadastroIndiceCorrecaoContribuicao from "./page/IndiceCorrecaoContribuicao/cadastro";
import IndiceCorrecaoContribuicao from "./page/IndiceCorrecaoContribuicao/listagem";
import SalarioMinimo from "./page/SalarioMinimoTeto/listagem";
import VinculoInstituidorDependente from "./page/VinculoInstituidorDependente/listagem";
import Auditoria from "./page/Auditoria";
import BeneficioEspecial from "./page/BeneficioEspecial";

export const configRoutes = [
  {
    exact: true,
    permissao:
      Permissions.SIMULACAO_APOSENTADORIA.SIMULAR_APOSENTADORIA_ATENDENTE,
    component: SimulacaoAtendente,
    tipo: "atendente",
    path: "/aposentadoria",
  },
  {
    exact: false,
    permissao: Permissions.SIMULACAO_APOSENTADORIA.SIMULAR_APOSENTADORIA,
    component: SimulacaoAposentadoria,
    tipo: "aposentadoria",
    path: "/aposentadoria",
  },
  {
    exact: false,
    permissaoAll: Permissions.SIMULACAO_PENSAO.SIMULACAO_PENSAO,
    component: SimulacaoPensao,
    tipo: "pensao",
    path: "/pensao",
  },
  {
    exact: false,
    permissao: Permissions.INDICE_CONTRIBUICAO.INDICE_CONTRIBUICAO,
    component: RouteChildren,
    tipo: "contribuicao",
    path: "/contribuicao",
    children: [
      {
        exact: true,
        permissao: Permissions.INDICE_BENEFICIOS.INDICE_CONTRIBUICAO,
        component: IndiceCorrecaoContribuicao,
        path: "",
      },
      {
        exact: false,
        permissao: Permissions.INDICE_BENEFICIOS.INDICE_CONTRIBUICAO,
        component: CadastroIndiceCorrecaoContribuicao,
        path: "/edicao/:id",
      },
      {
        exact: false,
        permissao: Permissions.INDICE_BENEFICIOS.INDICE_CONTRIBUICAO,
        component: CadastroIndiceCorrecaoContribuicao,
        path: "/cadastro",
      },
    ],
  },
  {
    exact: false,
    permissao: Permissions.INDICE_BENEFICIOS.INDICES_BENEFICIOS,
    component: RouteChildren,
    tipo: "beneficios",
    path: "/beneficios",
    children: [
      {
        exact: true,
        permissao: Permissions.INDICE_BENEFICIOS.INDICES_BENEFICIOS,
        component: IndiceCorrecaoBeneficio,
        path: "",
      },
      {
        exact: false,
        permissao: Permissions.INDICE_BENEFICIOS.INDICES_BENEFICIOS,
        component: CadastroIndiceCorrecaoBeneficio,
        path: "/edicao/:id",
      },
      {
        exact: false,
        permissao: Permissions.INDICE_BENEFICIOS.INDICES_BENEFICIOS,
        component: CadastroIndiceCorrecaoBeneficio,
        path: "/cadastro",
      },
    ],
  },
  {
    exact: false,
    permissao: Permissions.SALARIO_MINIMO_MAXIMO.SALARIO_MINIMO,
    component: RouteChildren,
    tipo: "salario",
    path: "/salario",
    children: [
      {
        exact: true,
        permissao: Permissions.SALARIO_MINIMO_MAXIMO.SALARIO_MINIMO,
        component: SalarioMinimo,
        path: "",
      },
      {
        exact: false,
        permissao: Permissions.SALARIO_MINIMO_MAXIMO.SALARIO_MINIMO,
        component: CadastroSalarioMinimoTeto,
        path: "/edicao/:id",
      },
      {
        exact: false,
        permissao: Permissions.SALARIO_MINIMO_MAXIMO.SALARIO_MINIMO,
        component: CadastroSalarioMinimoTeto,
        path: "/cadastro",
      },
    ],
  },
  {
    exact: false,
    permissao: Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_CPF_SEGURADO,
    component: RouteChildren,
    tipo: "processo",
    path: "/processo",
    children: [
      {
        exact: true,
        permissao: Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_CPF_SEGURADO,
        component: VinculoInstituidorDependente,
        path: "",
      },
      {
        exact: false,
        permissao: Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_CPF_SEGURADO,
        component: CadastroVinculoInstituidorDependente,
        path: "/edicao/:id",
      },
      {
        exact: false,
        permissao: Permissions.VINCULO_INSTI_PENSIONISTA.CONSULTAR_CPF_SEGURADO,
        component: CadastroVinculoInstituidorDependente,
        path: "/cadastro",
      },
    ],
  },
  {
    exact: false,
    permissao: Permissions.ADMINISTRACAO_SISTEMA.ADMIN_USUARIO,
    component: AdministracaoUsuario,
    tipo: "administracao",
    path: "/administracao/usuario",
  },
  {
    exact: false,
    permissao: Permissions.ADMINISTRACAO_SISTEMA.AUDITORIA,
    component: Auditoria,
    tipo: "auditoria",
    path: "/administracao/auditoria",
  },
  {
    exact: false,
    permissao: Permissions.BENEFICIO_ESPECIAL.BENEFICIO,
    component: BeneficioEspecial,
    tipo: "beneficio_especial",
    path: "/beneficio/especial",
  },
];
