const dev = {
  BASE_API: 'http://localhost:8080',
  KEYCLOAK_AUTH: 'https://autenticador-homo.goiasprev.go.gov.br/auth/',
  SA_API: 'https://sa-api-homo.goiasprev.go.gov.br/',
  AUDITORIA: "https://auditor-api-homo.goiasprev.go.gov.br/"
};

const homo = {
  BASE_API: 'https://simulador-api-homo.goiasprev.go.gov.br',
  KEYCLOAK_AUTH: 'https://autenticador-homo.goiasprev.go.gov.br/auth/',
  SA_API: 'https://sa-api-homo.goiasprev.go.gov.br/',
  AUDITORIA: "https://auditor-api-homo.goiasprev.go.gov.br/"
};

const prod = {
  BASE_API: 'https://simulador-api.goiasprev.go.gov.br',
  KEYCLOAK_AUTH: 'https://autenticador.goiasprev.go.gov.br/auth/',
  SA_API: 'https://sa.goiasprev.go.gov.br/',
  AUDITORIA: 'https://auditor-api.goiasprev.go.gov.br/'
};

const config = () => {
  if (process.env.REACT_APP_ENV === 'production') return prod;
  if (process.env.REACT_APP_ENV === 'homolog') return homo;
  return dev;
};

export default {
  MAX_ATTACHMENT_SIZE: 500000,
  ...config(),
};
