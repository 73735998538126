import axios from "axios";
import config from "../../config";
import { isEmpty } from "../../helper/util";

const BASE_API = config.BASE_API;

const getFuncionais = (cpf) => {
  return axios.get(BASE_API + "/funcional/" + cpf);
};

const getAverbacoes = (id, referencia) => {
  return axios.get(BASE_API + `/averbacoes/${id}`);
};

const obterRegrasAplicaveis = (idVinculo, referencia, dados) => {
  let url = BASE_API + "/simulacao/web/" + idVinculo;
  url = referencia ? url + "?referencia=" + referencia : url;

  return isEmpty(dados) && !dados
    ? axios.post(url).then((resp) => resp.data)
    : axios.post(url, dados).then((resp) => resp.data);
};

const visualizarRelatorioTempoContribuicao = (idVinculo) => {
  return axios
    .get(BASE_API + "/relatorios/tempocontribuicao/" + idVinculo, {
      responseType: "blob",
    })
    .then((resp) => resp.data)
    .then((data) => {
      const url = URL.createObjectURL(data);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.target = "_blank";
      a.click();
    });
};

const visualizarRelatorioFinal = (idVinculo, referencia, regra) => {
  const url = BASE_API + "/relatorios/completo/" + idVinculo;
  return axios
    .post(url, undefined, {
      params: {
        referencia: referencia ? referencia : undefined,
        regra,
        simulacaoFinal: true,
      },
      responseType: "blob",
    })
    .then((resp) => resp.data);
};

const visualizarRelatorioCompleto = (idVinculo, referencia, data) => {
  let url = BASE_API + "/relatorios/completo/" + idVinculo;
  url = referencia ? url + "?referencia=" + referencia : url;
  return axios
    .post(url, data, { responseType: "blob" })
    .then((resp) => resp.data);
};

const visualizarCalculoMedia = (idVinculo, referencia, stopDate) => {
  const url = BASE_API + "/relatorios/completo/" + idVinculo;
  return axios
    .post(url, undefined, {
      params: {
        referencia: referencia ? referencia : undefined,
        simulacaoFinal: true,
        stopDate,
      },
      responseType: "blob",
    })
    .then((resp) => resp.data);
};

export default {
  obterRegrasAplicaveis,
  getFuncionais,
  getAverbacoes,
  visualizarRelatorioTempoContribuicao,
  visualizarRelatorioFinal,
  visualizarRelatorioCompleto,
  visualizarCalculoMedia,
};
