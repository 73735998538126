import React from 'react';
import { useSecurityContext } from './useSecurityContext';
import { Redirect } from 'react-router-dom';
import Erro403 from '../../page/Erro403';

const Secured = ({ children, fallback, isShowErro403, ...permission }) => {
  const { hasPermission } = useSecurityContext();
  if (hasPermission(permission)) {
    return children;
  } else if (isShowErro403) {
    return fallback ? <Erro403 fallback={fallback} /> : <Redirect to="/acesso-negado" />;
  } else {
    return <></>;
  }
};

Secured.defaultProps = {
  fallback: null,
  isShowErro403: false
};

export default Secured;
