import axios from 'axios';
import config from '../../config';

const BASE_API = config.BASE_API;
const endpoint = BASE_API + '/indices/salario';

const salvar = (dados) => axios.post(endpoint, dados).then((resp) => resp.data);

const editar = (id, dados) => axios.put(`${endpoint}/${id}`, dados).then((resp) => resp.data);

const listar = (params) =>
  axios.get(endpoint, { params }).then((resp) => resp.data);

const obter = (id) =>
  axios.get(`${endpoint}/${id}`).then((resp) => resp.data);

const excluir = (id) =>
  axios.delete(`${endpoint}/${id}`).then((resp) => resp);

export default {
  listar,
  salvar,
  excluir,
  editar,
  obter,
};
