import React, { useContext, useMemo, useState } from 'react'
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import { applyMaskDouble, MASK_PERCENTUAL_DECIMAL, changeVirgulaPerPonto, MASK_NUMBER } from '../../../helper/mask';
import { Secured } from '../../../component';
import services from '../services';
import MessageContext from '../../../state/Message/Context';
import * as MessageActions from "../../../state/Message/actions";
import { Message } from '../../../Constantes/message.constants';
import { Permissions } from '../../../Constantes/permissions.constants';
import Table, {TypeFilter, TypeOperator, AlignType} from 'gp-table';
import 'gp-table/dist/index.css';
import { useRouteMatch, useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import config from '../../../config';
import useSecurityContext from '../../../component/Security/useSecurityContext';

const IndiceCorrecaoBeneficio = () => {

    const { messageDispatch } = useContext(MessageContext);
    const { hasPermission } = useSecurityContext();
    let history = useHistory();
    let { path } = useRouteMatch();
    const urlCadastro = `${path}/cadastro`;
    const urlEdicao = `${path}/edicao`;


    const onExcluir = async (id, obterLista, initialPage, pageSize, url, sortBy) => {
        services.excluir(id).then(resp => {
            messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_INDICE_PENSAO_EXCLUSAO_SUCESSO]));
            if (obterLista) {
                obterLista(initialPage, pageSize, url, sortBy, [], true);
            }
        }).catch(error => console.log(error));
    };

    const editar = (event, item) => {
        if (event) {
            event.preventDefault();
        }

        irParaEditar(item);
    }

    const columns = useMemo(() =>
        [
            {
                Header: "Referência",
                accessor: "ano",
                // width: '100',
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: "Ano",
                    minLength: 4,
                    operator: TypeOperator.IGUAL,
                    field: "ano",
                },
                alignItems: AlignType.CENTER,
                isVisible: true
            },
            {
                Header: "Ato que dispoẽ sobre os Reajustes",
                accessor: "descricao",
                width: '1450',
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Descrição",
                    minLength: 3,
                    operator: TypeOperator.CONTEM,
                    field: 'descricao',
                },
                alignItems: AlignType.LEFT
            },
            {
                Header: 'Jan',
                accessor: 'janeiro',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Janeiro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    mask: MASK_PERCENTUAL_DECIMAL,
                    field: 'janeiro',
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Fev',
                accessor: 'fevereiro',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Fevereiro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'fevereiro',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Mar',
                accessor: 'marco',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Março',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'marco',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto,
                },
                alignItems: AlignType.CENTER

            },
            {
                Header: 'Abr',
                accessor: 'abril',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Abril',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'abril',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Mai',
                accessor: 'maio',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Maio',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'maio',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Jun',
                accessor: 'junho',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Junho',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'junho',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Jul',
                accessor: 'julho',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Julho',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'julho',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Ago',
                accessor: 'agosto',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Agosto',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'agosto',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Set',
                accessor: 'setembro',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Setembro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'setembro',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Out',
                accessor: 'outubro',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Outubro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'outubro',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Nov',
                accessor: 'novembro',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Novembro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'novembro',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Dez',
                accessor: 'dezembro',
                width: '290',
                Cell: ({ value }) => applyMaskDouble(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: 'Dezembro',
                    minLength: 1,
                    operator: TypeOperator.IGUAL,
                    field: 'dezembro',
                    mask: MASK_PERCENTUAL_DECIMAL,
                    valueFilterApi: changeVirgulaPerPonto
                },
                alignItems: AlignType.CENTER
            }
        ], []
    )

    const onCadastrar = () => {
        history.push(urlCadastro);
    }

    const irParaEditar = (id) => {
        history.push(urlEdicao + '/' + id);
    }

    const [filtro, setFiltro] = useState({});
    const [value, setValue] = useState('');
    const [updateTable, setUpdateTable] = useState(null);

    const filtrar = (e) => {
        e.preventDefault();
        setFiltro({
            field: 'ano',
            value: value,
            fieldType: 'number',
            operator: TypeOperator.CONTEM
        });
        setUpdateTable(true);
    }

    return (
        <>
            <Secured hasAnyRole={Permissions.INDICE_BENEFICIOS.INDICES_BENEFICIOS} isShowErro403={true}>
                <div className='col-12'>
                    <Painel titulo='Percentual de Correção de Benefícios'>

                        <div className="row">
                            <div className="col-md-12">
                                <form className="form-inline form-filtro-tela">
                                    <div className='form-group col-11 col-sm-12 col-md-8 col-lg-8 col-xl-6 p-0 input-group-sm'>
                                        <label htmlFor='vigencia' className='col-form-label'>Filtro</label>
                                        <MaskedInput id='vigencia' name='vigencia'
                                            className={`form-control ml-4 mr-4`}
                                            placeholder='Vigência'
                                            value={value}
                                            guide={false}
                                            mask={MASK_NUMBER()}
                                            maxLength='7'
                                            autoComplete="off"
                                            onChange={(e) => { setValue(e.target.value); setUpdateTable(false) }} />
                                        <div>
                                            <button type='submit'
                                                className="btn btn-outline-dark btn-sm" onClick={filtrar}>
                                                <i className="fa fa-filter fa-fw" /> Filtrar
                                            </button>
                                        </div>
                                    </div>
                                    <Secured hasAnyRole={Permissions.INDICE_BENEFICIOS.BOTAO_CADASTRO}>
                                        <div className='col-sm-12 col-md-4 col-lg-4 col-xl-6 btn-novo mb-4 p-0'>
                                            <button type='button'
                                                className="btn btn-info btn-sm" onClick={onCadastrar}>
                                                <i className="fa fa-plus fa-fw" /> Novo
                                            </button>
                                        </div>
                                    </Secured>
                                </form>
                            </div>
                        </div>
                        <Secured hasAnyRole={Permissions.INDICE_BENEFICIOS.CONSULTAR_HISTORICO_INDICES}>
                            <Table
                                columns={columns}
                                hasPermissionsAction={Permissions.INDICE_BENEFICIOS.ACOES_TABELA}
                                hasPermissionEditar={Permissions.INDICE_BENEFICIOS.EDITAR_INDICE}
                                hasPermissionExcluir={Permissions.INDICE_BENEFICIOS.EXCLUIR_INDICE}
                                url={config.BASE_API + '/indices/pensao/filter'}
                                editAction={editar}
                                isFilterTable={false}
                                deleteAction={onExcluir}
                                editConditions={'editar'}
                                deleteConditions={'editar'}
                                isFormFilter={false}
                                filtroLista={filtro}
                                updateTable={updateTable}
                                message={Message}
                                hasPermission={hasPermission}
                            />
                        </Secured>


                    </Painel>
                </div>
            </Secured>
        </>
    )
}

export default IndiceCorrecaoBeneficio;