import React from "react";

import axios from 'axios';
import config from '../../config';

const BASE_API = config.BASE_API;

const getOpcoes = () => axios.get(BASE_API + '/opcoes');

function SelectParentesco({readOnly, value, ...props}) {
  const [opcoes, setOpcoes] = React.useState([]);

  React.useEffect(() => {
      getOpcoes()
      .then(res => res.data)
      .then(data => data.tipoParentesco)
      .then(setOpcoes);
  }, []);

  if(readOnly) return <span>{(opcoes.find(e => e.value === value) || {}).label}</span>;

  return (
    <select value={value} {...props}>
      <option value={null}>-</option>
      {opcoes.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  );
}

export default SelectParentesco;
