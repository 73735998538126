import React from "react";
import { Secured } from "../../../component";
import { verificarMesAnoValido } from "../../../helper/util";
import { Permissions } from "../../../Constantes/permissions.constants";
import services from "./../services";
import { QueryFilter } from "@ant-design/pro-form";

import {
  Form,
  Card,
  Table,
} from "antd";
import InputDate from "../../../component/InputDate";

const IndiceIpca = () => {
  const [data, setData] = React.useState();
  const [render, setRender] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const pagination = {
    page: 0,
    size: 10,
  };

  const formatarDataMesAno = (data) => {
    if (data) {
      let dataStr = data.toString();
      const dataFormatada =
        dataStr.substring(4) + "/" + dataStr.substring(0, 4);

      return dataFormatada;
    }
  };

  React.useEffect(() => {
    setLoading(true);
    setRender(false);

    services
      .obterIndicesIpca({ pagination, searchQueries: [{}] })
      .then((resp) => setData(resp))
      .finally(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [render]);

  const getIndices = (parametros) => {
    setLoading(true);
    services
      .obterIndicesIpca(parametros)
      .then((resp) => setData(resp))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (filterValues) getIndices(filterValues);
  }, [filterValues]);

  const onChangeTable = function (pagination, filters, sorte) {
    var pagination = {
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: "",
    };
    pagination.sort =
      sorte.order != undefined
        ? sorte.columnKey + "," + sorte.order.substr(0, sorte.order.length - 3)
        : "";
    setFilterValues({
      ...filterValues,
      pagination,
    });
  };

  const onSearch = async (values) => {
    if (values.mesAno) {
      const referenciaFormated = values.mesAno.replace("/", "");
      const ano = referenciaFormated.substring(2);
      const mes = referenciaFormated.substring(0, 2);
      const dataAnoMes = ano + mes;
      values.mesAno = dataAnoMes;
    }

    let searchQueries = [
      {
        field: "mesAno",
        fieldType: "number",
        operator: "LK",
        value: values.mesAno ? values.mesAno : "",
      },
    ];

    setFilterValues({
      pagination,
      searchQueries: searchQueries,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        title: "Referência",
        dataIndex: "mesAno",
        key: "mesAno",
        sorter: true,
        render: (text) => formatarDataMesAno(text),
      },
      {
        title: "Acumulado",
        dataIndex: "valorindiceCalc",
        key: "valorindiceCalc",
        sorter: true,
        render: (text) => `${text ? text.toString().replace(".", ",") : text}`,
      },
    ],
    []
  );

  return (
    <>
      <Secured
        hasAnyRole={Permissions.INDICE_CONTRIBUICAO.INDICE_CONTRIBUICAO}
        isShowErro403={true}
      >
        <Card title="Índices IPCA">
          <QueryFilter
            layout="vertical"
            defaultCollapsed
            className="filtro"
            onFinish={onSearch}
            onReset={onSearch}
          >
            <Form.Item
              label="Referência"
              name="mesAno"
              rules={[
                {
                  validator: async (info, value) => {
                    if (value && !verificarMesAnoValido(value)) {
                      await Promise.reject(new Error("Data inválida!"));
                    }
                  },
                },
              ]}
            >
              <InputDate format={"##/####"} placeholder="00/0000" />
            </Form.Item>
          </QueryFilter>
          <Secured
            hasAnyRole={
              Permissions.INDICE_CONTRIBUICAO.CONSULTAR_HISTORICO_INDICES
            }
          >
            <Table
              columns={columns}
              onChange={onChangeTable}
              dataSource={data?.content}
              pagination={{
                current: data?.pageable?.pageNumber
                  ? data?.pageable.pageNumber + 1
                  : 1,
                pageSize: data?.pageable?.pageSize,
                total: data?.totalElements,
                showSizeChanger: false,
                position: ["bottomCenter"],
              }}
              rowKey="id"
              loading={loading}
            />
          </Secured>
        </Card>
      </Secured>
    </>
  );
};

export default IndiceIpca;
