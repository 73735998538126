import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import MessageError from 'gp-message-error';
import Modal from 'gp-modal';
import 'gp-modal/dist/index.css';
import { changeVirgulaPerPonto, MASK_MONETARIO, applyMaskDouble } from '../../helper/mask';
import useForm from '../../helper/useForm';

const ModalAdicionarRubricas = ({ show, closeModal, adicionar, dadosEdicao }) => {

    const initValuesSchema = {
        nome: dadosEdicao && dadosEdicao.nome ? dadosEdicao.nome : '',
        valor: dadosEdicao && dadosEdicao.valor ? 'R$ ' + applyMaskDouble(dadosEdicao.valor) : '',
    }

    const [valuesSchema, setValuesSchema] = useState(initValuesSchema);

    useEffect(() => {
        setValuesSchema(initValuesSchema);
    }, [show]);  // eslint-disable-line

    const validationForm = {
        nome: {
            required: true
        },
        valor: {
            required: true
        },
    };

    const adicionarRubricas = () => {
        let valor = values.valor.replace(/[.]/g, '');
        valor = valor.replace('R$ ', '');
        const dados = {
            nome: values.nome,
            valor: Number(changeVirgulaPerPonto(valor)),
            incorpora: true,
            fixo: false,
            hasAlteracao: false
        }

        if (dadosEdicao) {
            dados.index = dadosEdicao.index;
        } else {
            setValuesSchema(initValuesSchema);
        }
        adicionar(dados);
    }

    const { values, errors, handleChange, handleBlur, handleSubmit, handleUpdateValues } = useForm(valuesSchema, adicionarRubricas, validationForm);

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    return (
        <Modal title='Adicionar Rubricas' showModal={show} closeModal={closeModal}>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className="form-group col-12 input-group-sm">
                            <label className="col-form-label" htmlFor="nome">
                                Nome
                            </label>
                            <input
                                className={`form-control ${errors.nome && "is-invalid"}`}
                                id="nome"
                                name="nome"
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nome}
                            />
                            <MessageError error={errors.nome} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6 input-group-sm">
                            <label className="col-form-label" htmlFor="valor">
                                Valor
                            </label>
                            <MaskedInput id="valor" name="valor"
                                className={`form-control ${errors.valor && "is-invalid"}`}
                                guide={false}
                                mask={MASK_MONETARIO}
                                placeholder='R$ 0,00'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.valor}
                                autoComplete="off" />
                            <MessageError error={errors.valor} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" onClick={closeModal}>
                            <i className='fas fa-times'></i>
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-info">
                            <i className='fas fa-plus'></i>
                            Adicionar
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ModalAdicionarRubricas;