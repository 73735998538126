import React from "react";
import { PatternFormat } from "react-number-format";
import { isValidDate } from "../../helper/util";

import "../../App.scss";

const InputDate = ({
  value = "",
  format = "##/##/####",
  placeholder,
  ...props
}) => {
  return (
    <PatternFormat
      {...props}
      value={value}
      format={format}
      placeholder={placeholder}
    />
  );
};

InputDate.Validator = async (_, value = "", format) => {
  if (value) {
    isValidDate(value, format)
      ? Promise.resolve()
      : await Promise.reject(new Error("Data Inválida!"));
  }
};
export default InputDate;
