import React, { Fragment } from 'react';

// import './styles.scss';
import { Columns } from './column';
import PropTypes from 'prop-types';
import { AlignType } from '../TableDemostrativa/alignType';

const TableDemostrativa = ({
  columns,
  children,
  list = [],
  totalPages
}) => {

  const renderTables = () => {
    return columns.map((item, index) => {
      return (
        <Fragment key={index}>
          {renderThead(item, index)}
          <tbody>{renderBody(item, index)}</tbody>
        </Fragment>
      );
    });
  };

  const renderBody = (item, index) => {
    if (children instanceof Array) {
      return children[index];
    } else if (totalPages === 0) {
      return (<tr><td colSpan={item.length} className="noResult"> Nenhum registro encontrado</td></tr>);
    } else {
      return children;
    }
  };

  const renderThead = (item, index) => {
    return (
      <thead className="thead-light">
        <tr key={index}>
          {renderTh(item)}
        </tr>
      </thead>
    );
  };

  const renderTh = (item) => {
    return item.map((column, indexColumn) => {
      if (column.visible === undefined || column.visible) {
        return (
          <th
            key={indexColumn}
            className={getClassTh(column)}
            scope="col"
            width={column.width ? column.width : ''}>
            {column.name + " "}
          </th>
        );
      }
      return <></>;
    });
  };

  const getClassTh = (item) => {
    switch (item.align) {
      case AlignType.LEFT: return 'text-left';
      case AlignType.RIGTH: return 'text-right';
      case AlignType.CENTER: return 'text-center';
      default: return '';
    }
  };

  return (
    <Fragment>
      <div className="">
        <table className="table table-sm table-bordered table-align table-striped">{renderTables()}</table>
      </div>
    </Fragment>
  );
};

TableDemostrativa.propTypes = {
  columns: PropTypes.arrayOf(Columns).isRequired,
  // sizeList: PropTypes.number,
  list: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element.isRequired),
    PropTypes.element.isRequired,
  ]),
};

export default TableDemostrativa;
