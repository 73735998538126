import axios from 'axios'
import config from '../../config';

const BASE_API = config.BASE_API;

const adicionar = (params) => axios.post(BASE_API + '/indices/pensao', params).then((resp) => resp.data);

const editar = (dados, id) => axios.put(BASE_API + '/indices/pensao/' + id, dados).then((resp) => resp.data);

const listar = (params) => axios.get(BASE_API + '/indices/pensao', { params }).then((resp) => resp.data);

const excluir = (id) => axios.delete(BASE_API + '/indices/pensao/' + id).then((resp) => resp);

const obter = (id) => axios.get(BASE_API + '/indices/pensao/' + id).then((resp) => resp.data);


export default {
    adicionar,
    listar,
    editar,
    excluir,
    obter
}

