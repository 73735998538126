import React, { useMemo, useContext, useState } from 'react';
import { Secured } from '../../../component';
import Table, {TypeFilter, TypeOperator, AlignType} from 'gp-table';
import 'gp-table/dist/index.css';
import { Permissions } from '../../../Constantes/permissions.constants';
import { changeVirgulaPerPonto, applyMaskMesAno, applyMaskMonetario, MASK_MES_ANO, MASK_MONETARIO, applyMaskMesAnoSemBarra, removeMaskMonetario, removeMask } from '../../../helper/mask';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css'
import { useRouteMatch, useHistory } from 'react-router-dom';
import services from '../services';
import MessageContext from '../../../state/Message/Context';
import * as MessageActions from "../../../state/Message/actions";
import { Message } from '../../../Constantes/message.constants';
import useSecurityContext from '../../../component/Security/useSecurityContext';
import config from '../../../config';

const SalarioMinimo = () => {

    const { messageDispatch } = useContext(MessageContext);
    const { hasPermission } = useSecurityContext();
    let history = useHistory();
    let { path } = useRouteMatch();
    const urlCadastro = `${path}/cadastro`;
    const urlEdicao = `${path}/edicao`;
    const [filtro, setFiltro] = useState({});
    const [value, setValue] = useState('');
    const [updateTable, setUpdateTable] = useState(null);

    const removeMaskMonetarioChangeVirgula = (value) => {
        value = removeMaskMonetario(value);
        return changeVirgulaPerPonto(value);
    }

    const columns = useMemo(() =>
        [
            {
                Header: "Vigência",
                accessor: "referencia",
                Cell: ({ value }) => applyMaskMesAno(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: "Referência",
                    minLength: 7,
                    operator: TypeOperator.IGUAL,
                    field: "refereciaa",
                    mask: MASK_MES_ANO,
                    valueFilterApi: applyMaskMesAnoSemBarra
                },
                alignItems: AlignType.CENTER,
            },
            {
                Header: "Salário Mínimo",
                accessor: "minimo",
                Cell: ({ value }) => applyMaskMonetario(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: "Salário Mínimo",
                    minLength: 3,
                    operator: TypeOperator.IGUAL,
                    mask: MASK_MONETARIO,
                    field: 'valor',
                    valueFilterApi: removeMaskMonetarioChangeVirgula,
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: 'Teto RGPS',
                accessor: 'teto',
                Cell: ({ value }) => applyMaskMonetario(value),
                filter: {
                    tipo: TypeFilter.NUMBER,
                    nome: "Teto RGPS",
                    minLength: 3,
                    operator: TypeOperator.IGUAL,
                    mask: MASK_MONETARIO,
                    field: 'valor',
                    valueFilterApi: removeMaskMonetarioChangeVirgula
                },
                alignItems: AlignType.CENTER
            }
        ], []
    )

    const onCadastrar = () => {
        history.push(urlCadastro);
    }

    const irParaEditar = (id) => {
        history.push(urlEdicao + '/' + id);
    }

    const editar = (event, item) => {
        if (event) {
            event.preventDefault();
        }

        irParaEditar(item);
    }

    const onExcluir = async (id, obterLista, initialPage, pageSize, url, sortBy) => {
        services.excluir(id).then(resp => {
            messageDispatch(MessageActions.addSucessMessage([Message.SALARIO_MINIMO_TETO_EXCLUSAO_SUCESSO]));
            if (obterLista) {
                obterLista(initialPage, pageSize, url, sortBy, [], true);
            }
        }).catch(error => console.log(error));;
    };

    const filtrar = (e) => {
        e.preventDefault();
        let valorFormatado = removeMask(value);

        if (valorFormatado.length >= 6) {
            const primeiroDigito = valorFormatado.slice(0, 2);
            const segundoDigito = valorFormatado.slice(2, 6);
            valorFormatado = segundoDigito + primeiroDigito;
        }
        setFiltro({
            field: 'referencia',
            value: valorFormatado,
            fieldType: 'number',
            operator: TypeOperator.CONTEM
        });
        setUpdateTable(true);
    }

    return (
        <>
            <div className="col-12">
                <Secured hasAnyRole={Permissions.SALARIO_MINIMO_MAXIMO.CONSULTAR_HISTORICO_SALARIO}>
                    <div className='col-12'>
                        <Painel titulo='Salário Mínimo e Teto RGPS'>

                            <div className="row">
                                <div className="col-md-12">
                                    <form className="form-inline form-filtro-tela">
                                        <div className='form-group col-11 col-sm-12 col-md-8 col-lg-8 col-xl-6 p-0 input-group-sm'>
                                            <label htmlFor='vigencia' className='col-form-label'>Filtro</label>
                                            <input id='vigencia' name='vigencia'
                                                className={`form-control ml-4 mr-4`}
                                                placeholder='Vigência'
                                                value={value}
                                                maxLength='7'
                                                onChange={(e) => { setValue(e.target.value); setUpdateTable(false) }} />
                                            <div>
                                                <button type='submit'
                                                    className="btn btn-outline-dark btn-sm" onClick={filtrar}>
                                                    <i className="fa fa-filter fa-fw" /> Filtrar
                                            </button>
                                            </div>
                                        </div>
                                        <Secured hasAnyRole={Permissions.SALARIO_MINIMO_MAXIMO.BOTAO_CADASTRO}>
                                            <div className='col-sm-12 col-md-4 col-lg-4 col-xl-6 btn-novo mb-4 p-0'>
                                                <button type='button'
                                                    className="btn btn-info btn-sm" onClick={onCadastrar}>
                                                    <i className="fa fa-plus fa-fw" /> Novo
                                                </button>
                                            </div>
                                        </Secured>
                                    </form>
                                </div>
                            </div>
                            <Secured hasAnyRole={Permissions.SALARIO_MINIMO_MAXIMO.CONSULTAR_HISTORICO_SALARIO}>
                                <Table
                                    columns={columns}
                                    hasPermissionsAction={Permissions.SALARIO_MINIMO_MAXIMO.ACOES_TABELA}
                                    hasPermissionEditar={Permissions.SALARIO_MINIMO_MAXIMO.EDITAR_SALARIO}
                                    hasPermissionExcluir={Permissions.SALARIO_MINIMO_MAXIMO.EXCLUIR_SALARIO}
                                    url={config.BASE_API + '/indices/salario/filter'}
                                    editAction={editar}
                                    isFilterTable={false}
                                    deleteAction={onExcluir}
                                    editConditions={'editar'}
                                    deleteConditions={'editar'}
                                    isFormFilter={false}
                                    filtroLista={filtro}
                                    updateTable={updateTable}
                                    message={Message}
                                    hasPermission={hasPermission}
                                />
                            </Secured>

                        </Painel>
                    </div>
                </Secured>
            </div>
        </>
    );
};

export default SalarioMinimo;
