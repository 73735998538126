import React, { Fragment, useEffect, useState } from "react";
import { Secured } from "../../../component";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Permissions } from "../../../Constantes/permissions.constants";
import services from "./../services";
import { Message } from "../../../Constantes/message.constants";
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Form, Row, Input, notification } from "antd";

import CRUDButtons from "../../../component/CRUDButtons";
import { verificarMesAnoValido } from "../../../helper/util";
import InputDate from "../../../component/InputDate";

const CadastroIndiceCorrecaoContribuicao = () => {
  const [loading, setLoading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = React.useState();
  let history = useHistory();
  let params = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      services
        .obter(params.id)
        .then((resp) => {
          setData(resp);
          let referenciaToString = resp.referencia.toString();
          const dataFormatada =
            referenciaToString.substring(4) +
            "/" +
            referenciaToString.substring(0, 4);

          resp.referencia = dataFormatada;

          const valorToString = resp.valor.toString();
          resp.valor = valorToString.replace(".", ",");

          form.setFieldsValue(resp);
        })
        .finally(() => setLoading(false));
    }
  }, [params.id]);

  const handleDelete = () => {
    services.excluir(params.id).then(() => {
      history.push(`/contribuicao`);
      notification.success({
        message: "Sucesso!",
        description: `Índice de Correção de Aposentadoria excluído com sucesso`,
        duration: 5,
      });
    });
  };

  const handleSubmit = (action) => {
    form.validateFields().then(async () => {
      try {
        const formData = form.getFieldsValue();
        formData.valor = formData.valor.replace(",", ".");
        const valorParsed = parseFloat(formData.valor); // Converter para ponto flutuante
        formData.valor = valorParsed;

        const referenciaFormated = formData.referencia.replace("/", "");
        const ano = referenciaFormated.substring(2);
        const mes = referenciaFormated.substring(0, 2);
        formData.referencia = ano + mes;

        if (action === "put") {
          await services.editar(formData, params.id).then((resp) => {
            history.push(`/contribuicao`);
            notification.success({
              message: "Sucesso!",
              description: `${Message.CADASTRO_INDICE_APOSENTADORIA_EDICAO_SUCESSO}`,
              duration: 5,

              onClick: () => history.push(`/contribuicao/edicao/${params.id}`),
            });
          });
        } else if (action === "post") {
          await services.adicionar(formData, params.id).then((resp) => {
            history.push(`/contribuicao`);
            notification.success({
              message: "Sucesso!",
              description: `${Message.INDICE_CORRECAO_APOSENTADORIA_SUCESSO}`,
              duration: 5,
            });
          });
        }
      } catch (e) {}
    });
  };

  return (
    <Fragment>
      <Secured
        hasAnyRole={Permissions.INDICE_CONTRIBUICAO.CADASTRAR_INDICE}
        isShowErro403={true}
      >
        <h1>Índice de Correção de Contribuição</h1>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={() => setDisableSaveButton(false)}
        >
          <Card
            loading={loading}
            title={`${
              params.id
                ? "Editar Índice de Correção de Contribuição"
                : "Criar Índice de Correção de Contribuição"
            }`}
          >
            <Row gutter={10}>
              <Col sm={24} md={4} lg={4}>
                <Form.Item
                  label="Referência"
                  name="referencia"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                    {
                      validator: async (info, value) => {
                        if (value && !verificarMesAnoValido(value)) {
                          await Promise.reject(new Error("Data inválida!"));
                        }
                      },
                    },
                  ]}
                >
                  <InputDate format={"##/####"} placeholder="00/0000" />
                </Form.Item>
              </Col>
              <Col sm={24} md={4} lg={4}>
                <Form.Item
                  label="Índice"
                  name="valor"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                    {
                      validator: async (info, value) => {
                        const valaorSemVirgula = parseFloat(value);

                        if (value && valaorSemVirgula < 1) {
                          await Promise.reject(
                            new Error("Índice não pode ser menor do que 1")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <NumericFormat
                    customInput={Input}
                    thousandsGroupStyle="lakh"
                    thousandSeparator="."
                    decimalSeparator=","
                    maxLength={6}
                    placeholder="0,0000"
                  />
                </Form.Item>
              </Col>
            </Row>

            <CRUDButtons
              disableSaveButton={disableSaveButton}
              handleSubmit={() => handleSubmit(params.id ? "put" : "post")}
              openDeleteModal={() => setOpenModal(true)}
              linkBackButton="/contribuicao"
              openModal={openModal}
              handleDelete={() => handleDelete()}
              handleCancel={() => setOpenModal(false)}
              action={"delete"}
              name={data?.nome}
              loading={loading}
            />
          </Card>
        </Form>
      </Secured>
    </Fragment>
  );
};

export default CadastroIndiceCorrecaoContribuicao;
