import React from "react";
import { Secured } from "../../component";
import { Permissions } from "../../Constantes/permissions.constants";

import {
  converterDataParaAnoMesDia,
  convertObjectNewDateToStr,
  transformarDataStringComBarra,
} from "../../helper/util";
import { Card, Col, Form, Row, Button } from "antd";
import InputCPF from "../../component/InputCPF";
import services from "../SimulacaoAposentadoria/services";
import { useLocation } from "react-router-dom";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import InputDate from "../../component/InputDate";
import SimulacaoAposentadoria from "../SimulacaoAposentadoria";

const SimulacaoAtendente = () => {
  const [dataFuncional, setDataFuncional] = React.useState();
  const [isSeguradoSelecionado, setSeguradoSelecionado] = React.useState(false);
  const [form] = Form.useForm();
  const [idVinculo, setIdVinculo] = React.useState();
  const [referencia, setReferencia] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { state } = useLocation();

  const selecionarSegurado = async () => {
    const formData = form.getFieldsValue();
    if (formData.referencia) {
      formData.referencia = converterDataParaAnoMesDia(formData.referencia);
    }
    setReferencia((prev) => formData.referencia);

    if (formData.cpf) {
      formData.cpf = formData.cpf.replace(/\D/g, "");
      form.validateFields().then(() => {
        setLoading((prev) => true);
        services
          .getFuncionais(formData.cpf)
          .then(async (resp) => {
            setDataFuncional((prev) => resp.data);
            setIdVinculo((prev) => resp.data.vinculos[0].id);
            setSeguradoSelecionado((prev) => true);
            setLoading((prev) => false);
          })
          .catch(() => {
            setSeguradoSelecionado((prev) => false);
            setLoading((prev) => false);
          });
      });
    }
  };

  React.useEffect(() => {
    if (state) {
      const formData = form.getFieldsValue();
      if (formData.referencia) {
        formData.referencia = converterDataParaAnoMesDia(formData.referencia);
      }
      setReferencia((prev) => formData.referencia);

      if (formData.cpf) {
        formData.cpf = formData.cpf.replace(/\D/g, "");
        form.validateFields().then(() => {
          setLoading((prev) => true);
          services
            .getFuncionais(formData.cpf)
            .then(async (resp) => {
              setDataFuncional((prev) => resp.data);
              setIdVinculo((prev) => resp.data.vinculos[0].id);
              setSeguradoSelecionado((prev) => true);
              setLoading((prev) => false);
            })
            .catch(() => {
              setSeguradoSelecionado((prev) => false);
              setLoading((prev) => false);
            });
        });
      }
    }
  }, [state]);

  const clearAll = () => {
    form.setFieldsValue({ cpf: "" });
    setSeguradoSelecionado((prev) => false);
    setDataFuncional();
    setReferencia();
    setLoading((prev) => false);
  };

  return (
    <>
      <Secured
        hasAnyRole={
          Permissions.SIMULACAO_APOSENTADORIA.SIMULAR_APOSENTADORIA_ATENDENTE
        }
        isShowErro403={true}
      >
        <h1>Simulação de Aposentadoria e Inativação</h1>

        <Form
          layout="vertical"
          form={form}
          initialValues={{
            vinculos: dataFuncional?.vinculos,
            referencia: state
              ? transformarDataStringComBarra(state.referencia)
              : convertObjectNewDateToStr(),
            cpf: state ? state.cpf : "",
          }}
          scrollToFirstError={true}
          style={{ marginBottom: "12px" }}
        >
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Row gutter={[10]}>
                <Col span={24}>
                  <Card title="Identifique o Segurado">
                    <Row gutter={10}>
                      <Col>
                        <Form.Item
                          name={"cpf"}
                          label="CPF"
                          rules={[
                            { validator: InputCPF.Validator },
                            { required: true, message: "Campo obrigatório!" },
                          ]}
                        >
                          <InputCPF
                            disabled={isSeguradoSelecionado}
                            readOnly={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={["referencia"]}
                          label="Referência"
                          rules={[
                            { required: true, message: "Campo obrigatório!" },
                            {
                              validator: async (info, value) => {
                                return InputDate.Validator(
                                  "referencia",
                                  value,
                                  "##/##/####"
                                );
                              },
                            },
                          ]}
                        >
                          <InputDate
                            disabled={isSeguradoSelecionado}
                            placeholder="00/00/0000"
                            format="##/##/####"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            hidden={isSeguradoSelecionado}
                            icon={<SearchOutlined />}
                            type="primary"
                            htmlType="submit"
                            onClick={() => selecionarSegurado()}
                            loading={loading}
                          >
                            Selecionar Segurado
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            hidden={!isSeguradoSelecionado}
                            icon={<ClearOutlined />}
                            onClick={() => clearAll()}
                          >
                            Limpar
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        {isSeguradoSelecionado && (
          <>
            <SimulacaoAposentadoria
              atendente={true}
              isSeguradoSelecionado={isSeguradoSelecionado}
              idVinculoFuncional={idVinculo}
              funcional={dataFuncional}
              referencia={referencia}
              loadingg={loading}
            />
          </>
        )}
      </Secured>
    </>
  );
};

export default SimulacaoAtendente;
