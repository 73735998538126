import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import Datepicker from 'gp-datepicker';
import If from 'gp-if';
import MessageError from 'gp-message-error';
import Modal from 'gp-modal';
import 'gp-modal/dist/index.css';
import Step from 'gp-step';
import 'gp-step/dist/index.css';
import 'gp-datepicker/dist/index.css'
import { MASK_MONETARIO, MASK_PERCENTUAL_DECIMAL, applyMaskDouble, applyMaskAnoMesDia, removeMask, MASK_CPF, applyMaskDiaMesAno  } from "../../helper/mask";
import useForm from '../../helper/useForm';
import Services from './services';
import ModalConfirm from 'gp-modal-confirm';
import { Message } from '../../Constantes/message.constants';
import CPF from 'gp-cpf';
import { validateCpf } from '../../helper/validation';
import { conformToMask } from 'react-text-mask';
import Alert from '../../component/Alert';
import * as MessageActions from "../../state/Message/actions";
import MessageContext from '../../state/Message/Context';
import SelectParentesco from '../../component/Select/SelectParentesco';

const ModalAdicionarDependentes = ({ show, closeModal, adicionar, dadosEdicao, hasObitoAfter2020 }) => {

    const [dependentes, setDependentes] = useState([]);
    const [isEditar, setIsEditar] = useState(dadosEdicao ? dadosEdicao.fixo : false);
    const [hasPensao, setHasPensao] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [excluirOutroBeneficio, setExcluirOutroBeneficio] = useState(undefined);

    const applyMaskCpf = (cpf) => {
        let mask = MASK_CPF;
        mask = mask.filter((val) => val !== '[]');
        let retorno = conformToMask(cpf, mask, { guide: false });

        return retorno.conformedValue;
    }

    const initValuesSchema = {
        cpf: dadosEdicao ? applyMaskCpf(dadosEdicao.cpf) : '',
        nome: dadosEdicao ? dadosEdicao.nome : '',
        parentesco: dadosEdicao ? dadosEdicao.parentesco.toString() : '0',
        dependencia: dadosEdicao ? dadosEdicao.dependencia.toString() : '0',
        nascimento: dadosEdicao ? new Date(dadosEdicao.nascimento + ' 00:00:00') : undefined,
        alimentacao: dadosEdicao && dadosEdicao.alimentacao ? dadosEdicao.alimentacao.toFixed(2).replace('.', ',') : '',
        dataTermino: dadosEdicao && dadosEdicao.dataTermino ? new Date(dadosEdicao.dataTermino + ' 00:00:00') : undefined
    }

    const [valuesSchema, setValuesSchema] = useState(initValuesSchema);
    const [outrosBeneficios, setOutrosBeneficios] = useState([]);
    const { messageDispatch } = useContext(MessageContext);

    useEffect(() => {
        setValuesSchema(initValuesSchema);
        setIsEditar(!!dadosEdicao);
        if (hasObitoAfter2020) {
            const isPensao = dadosEdicao && dadosEdicao.beneficios;
            setHasPensao(isPensao);
            let beneficios = dadosEdicao && dadosEdicao.beneficios ? dadosEdicao.beneficios : [];
            if (beneficios.length > 0) {
                beneficios = organizarListaBeneficios(beneficios);
            }
            setOutrosBeneficios(beneficios);
        }
    }, [show]);  // eslint-disable-line

    useEffect(() => {

        setValuesSchema(initValuesSchema);

        const obterDependentes = async () => {
            const dependentes = await Services.obterDependencia();
            setDependentes(dependentes);
        }

        obterDependentes();

    }, []);  // eslint-disable-line

    const validationForm = {
        cpf: {
            required: true,
            validator: [{
                tipo: validateCpf,
                error: Message.MSG002_CPF_INVALIDO,
            }]
        },
        nome: {
            required: true
        },
        parentesco: {
            required: true
        },
        dependencia: {
            required: true
        },
        nascimento: {
            required: true
        },
        alimentacao: {
            required: false
        },
        dataTermino: {
            required: false
        },
        termino: {
            required: false
        }
    };


    const organizarListaBeneficios = (beneficios) => {
        let segundoBeneficios = [];
        beneficios.map(item => {
            segundoBeneficios.push({
                tipo: item.tipo,
                valor: 'R$ ' + applyMaskDouble(item.valor),
                dataTermino:  item.dataTermino ? applyMaskDiaMesAno(item.dataTermino) : undefined
            });
            return item;
        });
        return segundoBeneficios;
    }

    const adicionarDependentes = () => {
        const dados = {
            cpf: removeMask(values.cpf),
            nome: values.nome,
            parentesco: parseInt(values.parentesco),
            dependencia: parseInt(values.dependencia),
            nascimento: format((values.nascimento), 'yyyy-MM-dd'),
            alimentacao: values.alimentacao && typeof values.alimentacao === 'string' ? parseFloat(values.alimentacao.replace(/,/g, '.')) : values.alimentacao,
            fixo: false,
            dataTermino: values.dataTermino ? format((values.dataTermino), 'yyyy-MM-dd') : undefined,
        }
        if (hasPensao && getShowOutroBeneficios()) {
            dados.beneficios = formatarListaBeneficios();
        }

        if (dadosEdicao) {
            dados.index = dadosEdicao.index;
            dados.valor = dadosEdicao.valor;
            dados.percentual = dadosEdicao.percentual;
            dados.fixo = dadosEdicao.fixo;
        }
        adicionar(dados);
    }

    const formatarListaBeneficios = () => {
        let beneficios = [];
        outrosBeneficios.map(item => {
            beneficios.push({
                tipo: item.tipo,
                valor: typeof item.valor === 'string' ? parseFloat(item.valor.replace('R$ ', '').replace(/\./g, '').replace(/,/g, '.')) : item.valor,
                dataTermino: item.dataTermino ? applyMaskAnoMesDia(item.dataTermino) : undefined,
            });
            return item;
        });
        return beneficios
    }
    const cancelar = () => {}

    const openCalendarTerminioDependente = (date) => {
        setValuesSchema({
            ...values,
            dataTermino: date
        });
    }

    const onBlurDataTermino = (event) => {
        const target = event.target;
        let value = target.value;
        if (value === '') {
            openCalendarTerminioDependente(undefined);
        }

    }

    const { values, errors, handleChange, handleBlur, handleBlurWithoutCallback,  handleSubmit, handleUpdateValues } = 
        useForm(valuesSchema, adicionarDependentes, validationForm, cancelar, onBlurDataTermino);


    const openCalendarNascimento = (date) => {
        setValuesSchema({
            ...values,
            nascimento: date
        });
    }

    const openCalendarTerminio = (date) => {
        setValuesSchema({
            ...values,
            termino: date
        });
    }

    const onChangePossuiOutraPensao = () => {
        setHasPensao(!hasPensao);
    }

    const adicionarOutroBeneficio = () => {
        if (values.tipo !== undefined && values.tipo !== '' &&  values.valor !== undefined && values.valor !== '') {
            const item = {
                tipo: values.tipo,
                valor: values.valor,
                dataTermino: values.termino ? format((values.termino), 'dd/MM/yyyy') : undefined
            }
            setOutrosBeneficios([
                ...outrosBeneficios,
                item
            ]);
            limparOutrosBeneficios();
        } else {
            messageDispatch(MessageActions.addInfoMessage([Message.PREENCHA_TIPO_VALOR]));
        }

    }

    const limparOutrosBeneficios = () => {
        setValuesSchema({
            ...values,
            tipo: '',
            valor: '',
            termino: undefined,
            parentesco: values.parentesco,
            dependencia: values.dependencia,
        });
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const onExcluir = () => {
        setOutrosBeneficios(outrosBeneficios.filter(item => item !== excluirOutroBeneficio));
        closeModalConfirm();
    }

    const openModalConfirm = (event, item) => {
        setExcluirOutroBeneficio(item);
        event.preventDefault();
        setShowModalConfirm(true);
    }

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    const getShowOutroBeneficios = () => {
        return hasObitoAfter2020 && (values.parentesco === '2' || values.parentesco === '3' || values.parentesco === '7' || values.parentesco === '10');
    }


    const getNomeTipo = (tipo) => {
        switch (tipo) {
            case 'aposentadoriaRGPS': return 'Aposentadoria RGPS';
            case 'aposentadoriaRPPS': return 'Aposentadoria RPPS';
            case 'pensaoRGPS': return 'Pensao RGPS';
            case 'pensaoRPPS': return 'Pensao RPPS';
            default: return '';
        }
    }

    return (
        <>
            <Modal title='Adicionar Dependente para Cálculo' showModal={show} closeModal={closeModal} classeSize={hasPensao ? 'modal-lg modal-dialog-scrollable' : 'modal-lg'}>
                <div className="modal-body">
                    <Alert classAlert="p-0" isTimeout={false} />
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="form-group col-md-6 col-lg-4 col-xl-3 input-group-sm">
                                <CPF
                                    id='cpf'
                                    label='CPF'
                                    values={values}
                                    handleBlur={handleBlurWithoutCallback}
                                    handleChange={handleChange}
                                    
                                    errors={errors}
                                />
                            </div>
                            <div className="form-group col-9 input-group-sm">
                                <label className="col-form-label" htmlFor="nome">
                                    Nome
                                </label>
                                <input
                                    className={`form-control ${errors.nome && "is-invalid"}`}
                                    id="nome"
                                    name="nome"
                                    type="text"
                                    onBlur={handleBlurWithoutCallback}
                                    onChange={handleChange}
                                    value={values.nome}
                                    
                                />
                                <MessageError error={errors.nome} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6 input-group-sm">
                                <label className="col-form-label" htmlFor="parentesco">
                                    Parentesco
                            </label>
                                <SelectParentesco id="parentesco" className={`custom-select ${errors.codigo && "is-invalid"}`}
                                    onChange={handleChange} name='parentesco' 
                                    onBlur={handleBlurWithoutCallback}
                                    value={values.parentesco}>
                                </SelectParentesco>

                            </div>
                            <Datepicker id='nascimento'
                                label='Data de Nascimento'
                                errors={errors}
                                date={values.nascimento}
                                callback={openCalendarNascimento}
                                
                                onBlur={handleBlurWithoutCallback}
                                classes="col-md-6" />
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6 input-group-sm">
                                <label className="col-form-label" htmlFor="dependencia">
                                    Tipo de Dependencia
                            </label>
                                <select id="dependencia" className="custom-select" name='dependencia' onChange={handleChange} 
                                    value={values.dependencia}
                                    onBlur={handleBlurWithoutCallback}
                                >
                                    {
                                        dependentes.map((item, index) => <option key={item.codigo} value={item.codigo}>{item.nome}</option>)
                                    }
                                </select>
                            </div>
                            <Datepicker id='dataTerminio'
                                        label='Data de Término'
                                        date={values.dataTermino}
                                        callback={openCalendarTerminioDependente}
                                        onBlur={handleBlur}
                                        classes="col-md-4" />
                            <div className="form-group col-md-6 input-group-sm">
                                <label className="col-form-label" htmlFor="alimentacao">
                                    % de Pensão Alimentícia
                                </label>
                                <MaskedInput
                                    className="form-control"
                                    id="alimentacao"
                                    autoComplete="off"
                                    onBlur={handleBlurWithoutCallback}
                                    name="alimentacao"
                                    mask={MASK_PERCENTUAL_DECIMAL}
                                    placeholder='0,00'
                                    
                                    onChange={handleChange}
                                    value={values.alimentacao}
                                />
                            </div>
                            
                        </div>
                        <If test={getShowOutroBeneficios()}>
                            <div className='row'>
                                <div className="form-group col-md-6 input-group-sm">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="pensao" name="pensao" onChange={onChangePossuiOutraPensao} checked={hasPensao} />
                                        <label className="form-check-label" htmlFor="pensao">Possui outro benefício</label>
                                    </div>
                                </div>
                            </div>

                        </If>
                        <If test={getShowOutroBeneficios() && hasPensao}>
                            <Step titulo='Outros Benefícios' classStep="p-0">
                                <div className="row">
                                    <div className="form-group col-md-3 input-group-sm">
                                        <label className="col-form-label" htmlFor="tipo">
                                            Tipo
                                    </label>
                                        <select id="tipo" className="custom-select" name='tipo' onChange={handleChange} value={values.tipo} >
                                            <option value="">-</option>
                                            <option value="aposentadoriaRGPS">Aposentadoria RGPS</option>
                                            <option value="aposentadoriaRPPS">Aposentadoria RPPS</option>
                                            <option value="pensaoRGPS">Pensão RGPS</option>
                                            <option value="pensaoRPPS">Pensão RPPS</option>
                                        </select>
                                        <MessageError error={errors.tipo} />
                                    </div>
                                    <div className="form-group col-md-3 input-group-sm">
                                        <label className="col-form-label" htmlFor="valor">
                                            Valor
                                        </label>
                                        <MaskedInput id="valor" name="valor"
                                            className={`form-control ${errors.valor && "is-invalid"}`}
                                            guide={false}
                                            mask={MASK_MONETARIO}
                                            placeholder='R$ 0,00'
                                            onChange={handleChange}
                                            value={values.valor}
                                            autoComplete="off" />
                                        <MessageError error={errors.valor} />
                                    </div>
                                    <Datepicker id='termino'
                                        label='Data de Término'
                                        date={values.termino}
                                        callback={openCalendarTerminio}
                                        onBlur={handleBlurWithoutCallback}
                                        classes="col-md-4" />
                                    <div className="btn-group-vertical">
                                        <button type="button" className="btn btn-info btn-sm" onClick={adicionarOutroBeneficio}>
                                            <i className='fas fa-plus' />
                                        Adicionar
                                    </button>
                                    </div>
                                </div>
                                <div>
                                    <table className="table table-sm table-bordered table-align table-striped">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="text-center">Tipo</th>
                                                <th className="text-center">Valor</th>
                                                <th className="text-center">Data de término</th>
                                                <th className="text-center" width="5%">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                outrosBeneficios.length > 0 ?
                                                    outrosBeneficios.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className='text-center'>{getNomeTipo(item.tipo)}</td>
                                                            <td className='text-center'>{item.valor}</td>
                                                            <td className='text-center'>{item.dataTermino}</td>
                                                            <td className='text-center'>
                                                                <button
                                                                    type="button"
                                                                    onClick={(event) => openModalConfirm(event, item)}
                                                                    title='Excluir'
                                                                    className="btn btn-outline-danger btn-sm ml-2" >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :

                                                    <tr><td colSpan='4' className="noResult"> Nenhum registro encontrado</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Step>
                        </If>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-dark" onClick={closeModal}>
                                <i className='fas fa-times' />
                            Cancelar
                        </button>
                            <button type="submit" className="btn btn-info">
                                <i className='fas fa-plus' />
                            Adicionar
                        </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <ModalConfirm
                show={showModalConfirm}
                actionClose={closeModalConfirm}
                message={Message.CONFIRMACAO_EXCLUSAO}
                title={Message.TITLE_CONFIRMACAO}
                actionConfirm={onExcluir}
            />
        </>
    )
}

export default ModalAdicionarDependentes;