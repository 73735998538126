import React from "react";
import { PatternFormat } from "react-number-format";
import { validateBr } from "js-brasil";

const InputCPF = ({
  value = "",
  onSearch,
  onClear,
  loading,
  ref,
  ...props
}) => {
  return (
    <PatternFormat
      {...props}
      value={value}
      format="###.###.###-##"
      loading={loading}
      placeholder={"000.000.000-00"}
    />
  );
};

InputCPF.Validator = (_, value = "") =>
  value.replace(/\D/g, "").length < 11 || validateBr.cpf(value)
    ? Promise.resolve()
    : Promise.reject(new Error("CPF inválido!"));

export default InputCPF;
