import React, { useState, useEffect, useContext } from 'react'
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import Datepicker, { DatepickerType } from 'gp-datepicker';
import 'gp-datepicker/dist/index.css';
import useForm from '../../../helper/useForm';
import MaskedInput from 'react-text-mask';
import { applyMaskDouble, MASK_PERCENTUAL_DECIMAL, changeVirgulaPerPonto } from '../../../helper/mask';
import { Secured } from '../../../component';
import services from '../services';
import MessageContext from '../../../state/Message/Context';
import * as MessageActions from "../../../state/Message/actions";
import { Message } from '../../../Constantes/message.constants';
import MessageError from 'gp-message-error';
import { format } from 'date-fns';
import { copyObject, getURL } from '../../../helper/util';
import { Permissions } from '../../../Constantes/permissions.constants';
import useSecurityContext from '../../../component/Security/useSecurityContext';
import If from 'gp-if';
import { useHistory } from 'react-router-dom';
import MainHomeContext from '../../../state/Home/Context';

const CadastroIndiceCorrecaoBeneficio = ({ match }) => {

    const { mainHome } = useContext(MainHomeContext);
    const { messageDispatch } = useContext(MessageContext);
    const { hasPermission } = useSecurityContext();
    let history = useHistory();
    let { id } = match.params;

    const mes = [
        {
            mes: 'Jan',
            nomeMes: 'janeiro'
        },
        {
            mes: 'Fev',
            nomeMes: 'fevereiro'
        },
        {
            mes: 'Mar',
            nomeMes: 'marco'
        },
        {
            mes: 'Abr',
            nomeMes: 'abril'
        },
        {
            mes: 'Mai',
            nomeMes: 'maio'
        },
        {
            mes: 'Jun',
            nomeMes: 'junho'
        },
        {
            mes: 'Jul',
            nomeMes: 'julho'
        },
        {
            mes: 'Ago',
            nomeMes: 'agosto'
        },
        {
            mes: 'Set',
            nomeMes: 'setembro'
        },
        {
            mes: 'Out',
            nomeMes: 'outubro'
        },
        {
            mes: 'Nov',
            nomeMes: 'novembro'
        },
        {
            mes: 'Dez',
            nomeMes: 'dezembro',
            value: ''
        }
    ]

    const initialValuesSchema = {
        ano: undefined,
        descricao: '',
        janeiro: '',
        fevereiro: '',
        marco: '',
        abril: '',
        maio: '',
        junho: '',
        julho: '',
        agosto: '',
        setembro: '',
        outubro: '',
        novembro: '',
        dezembro: ''
    }

    const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
    const [isEditar, setIsEditar] = useState(false);

    const cancelar = () => {
        setValuesSchema(initialValuesSchema);
        setIsEditar(false);
    }

    const validationForm = {
        ano: {
            required: true
        },
        descricao: {
            required: true
        },
        janeiro: {
            required: true
        },
        fevereiro: {
            required: true
        },
        marco: {
            required: true
        },
        abril: {
            required: true
        },
        maio: {
            required: true
        },
        junho: {
            required: true
        },
        julho: {
            required: true
        },
        agosto: {
            required: true
        },
        setembro: {
            required: true
        },
        outubro: {
            required: true
        },
        novembro: {
            required: true
        },
        dezembro: {
            required: true
        }
    };

    useEffect(() => {
        if (id) {
            services.obter(id).then((response) => {

                let dadosEdicao = copyObject(response);
                Object.keys(dadosEdicao).forEach(item => {
                    if (item !== 'descricao' && item !== 'ano' && item !== 'id' && item !== 'dataInclusao' && item !== 'editar') {
                        dadosEdicao[item] = applyMaskDouble(dadosEdicao[item]);
                    }
                });
                dadosEdicao.ano = new Date(dadosEdicao.ano + '-01-01 00:00:00');
                setValuesSchema(dadosEdicao);
                setIsEditar(true);
            });
        }

    }, [id]);

    const openCalendar = (date) => {

        setValuesSchema({
            ...values,
            ano: date
        });
    }

    const getIndiceMes = () => {
        return mes.map((item, index) => {
            return (
                <div className='form-group col-4 col-md-2 input-group-sm' key={index}>
                    <label htmlFor={'mes' + item.mes} className='col-form-label'>{item.mes + ' (%)'}</label>
                    <MaskedInput id={'mes' + item.mes} name={item.nomeMes}
                        className={`form-control ${errors[item.nomeMes] && "is-invalid"}`}
                        guide={false}
                        mask={MASK_PERCENTUAL_DECIMAL}
                        placeholder='0,00'
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[item.nomeMes] || ""} />
                    <MessageError error={errors[item.nomeMes]} />
                </div>
            )
        });
    }

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    const adicionar = () => {
        services.adicionar(formataRequest()).then(response => {
            messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_INDICE_PENSAO_SUCESSO]));
            setValuesSchema(initialValuesSchema);
            history.push(getURL("/beneficios", 'beneficios', mainHome));
        }).catch(error => console.log(error));;
    }

    const formataRequest = () => {
        let dados = copyObject(values);
        dados.ano = Number(format((values.ano), 'yyyy'));
        Object.keys(dados).forEach(item => {
            if (item !== 'descricao' && item !== 'ano') {
                dados[item] = changeVirgulaPerPonto(dados[item]);
            }
        });

        return dados;
    }

    const onSubmit = () => {
        if (isEditar) {
            onEditar();
        } else {
            adicionar();
        }
    }

    const onEditar = () => {
        services.editar(formataRequest(), values.id).then(resp => {
            setIsEditar(false);
            messageDispatch(MessageActions.addSucessMessage([Message.CADASTRO_INDICE_PENSAO_EDICAO_SUCESSO]));
            setValuesSchema(initialValuesSchema);
            history.push(getURL("/beneficios", 'beneficios', mainHome));
        }).catch(error => console.log(error));;
    }

    const onCancelar = () => {
        history.push(getURL("/beneficios", 'beneficios', mainHome));
    }

    const getPermissaoCadastrar = () => hasPermission({ hasAnyRole: Permissions.INDICE_BENEFICIOS.BOTAO_CADASTRO });

    const { values, errors, handleChange, handleBlur, handleSubmit, handleUpdateValues, handleReset } =
        useForm(valuesSchema, onSubmit, validationForm, cancelar);

    return (
        <>
            <Secured hasAnyRole={Permissions.INDICE_BENEFICIOS.CADASTRAR_INDICE} isShowErro403={true}>
                <div className='col-12'>
                    <Painel titulo='Percentual de Correção de Benefícios'>
                        <form onSubmit={handleSubmit}>
                            <h5>Novo Cadastro</h5>

                            <div className='row'>
                                <Datepicker id='ano'
                                    label='Ano de Referência'
                                    date={values.ano}
                                    classes="col-md-3"
                                    typeDatepicker={DatepickerType.YEAR}
                                    onBlur={handleBlur}
                                    callback={openCalendar}
                                    errors={errors} />

                                <div className='form-group col-md-7 col-xl-5 input-group-sm'>
                                    <label htmlFor='atoDispoeReajustes' className='col-form-label'>Ato que dispõe sobre os Reajustes</label>
                                    <input id='atoDispoeReajustes' name='descricao'
                                        className={`form-control ${errors.descricao && "is-invalid"}`}
                                        placeholder='Portaria n° 00/0000'
                                        value={values.descricao}
                                        onBlur={handleBlur}
                                        maxLength='80'
                                        onChange={handleChange} />
                                    <MessageError error={errors.descricao} />
                                </div>
                            </div>
                            <div className='row'>
                                {getIndiceMes()}
                            </div>
                            <div className='row col-md-12 button-right mt-4 ml-3'>
                                <button type='button' className='btn btn-outline-dark mr-3 btn-sm' onClick={onCancelar}>
                                    <i className="fas fa-times" />
                                        Cancelar
                                    </button>
                                <button type='button' className='btn btn-outline-dark mr-3 btn-sm' onClick={handleReset}>
                                    <i className="fas fa-eraser" />
                                        Limpar
                                    </button>
                                <If test={getPermissaoCadastrar() || isEditar}>
                                    <button type="submit" className='btn btn-info btn-sm'>
                                        <i className={['fas', isEditar ? 'fa-save' : 'fa-plus'].join(" ")} />
                                        {isEditar ? 'Salvar' : 'Adicionar'}
                                    </button>
                                </If>
                            </div>
                        </form>
                    </Painel>
                </div>
            </Secured>
        </>
    )
}

export default CadastroIndiceCorrecaoBeneficio;