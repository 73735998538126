import axios from "axios";
import config from "../../config";

const BASE_API = config.BASE_API;

const adicionar = (dados) =>
  axios.post(BASE_API + "/indices/inpc", dados).then((resp) => resp.data);

const editar = (dados, id) =>
  axios.put(BASE_API + "/indices/inpc/" + id, dados).then((resp) => resp.data);

const excluir = (id) =>
  axios.delete(BASE_API + "/indices/inpc/" + id).then((resp) => resp.data);

const obter = (id) =>
  axios.get(BASE_API + "/indices/inpc/" + id).then((resp) => resp.data);

const obterIndices = (params) =>
  axios
    .post(BASE_API + "/indices/inpc/filter", { ...params })
    .then((resp) => resp.data);

const obterIndicesIpca = (params) =>
  axios
    .post(BASE_API + "/indices/ipca/filter", { ...params })
    .then((resp) => resp.data);

const obterIpca = (id) =>
  axios.get(BASE_API + "/indices/ipca/" + id).then((resp) => resp.data);

export default {
  adicionar,
  editar,
  obterIndices,
  obterIndicesIpca,
  obterIpca,
  excluir,
  obter,
};
