import { format, isAfter, isValid, parseISO } from 'date-fns';
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Secured } from '../../component';
import { notification } from "antd";

import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import useSecurityContext from '../../component/Security/useSecurityContext';

import TableDemostrativa from '../../component/TableDemostrativa';
import { Message } from '../../Constantes/message.constants';
import { Permissions } from '../../Constantes/permissions.constants';
import { applyMaskDouble, removeMask, applyMaskAnoMesDia, applyMaskMonetarioThousandSeparator, MASK_NUMBER } from '../../helper/mask';
import useForm from '../../helper/useForm';
import { validateCpf } from '../../helper/validation';
import { configTable } from './configTable';
import { configTableDependentes } from './configTableDependentes';
import ModalAdicionarDependentes from './ModalAdicionarDependentes';
import ModalAdicionarRubricas from './ModalAdicionarRubricas';
import Services from './services';
import ReactTooltip from 'react-tooltip';
import config from './../../config';

import CPF from 'gp-cpf';
import ModalConfirm from 'gp-modal-confirm';
import Datepicker, { DatepickerType }  from 'gp-datepicker';
import If from 'gp-if';
import Step from 'gp-step';
import ToggleSwitch from 'gp-toggle';
import Table, { AlignType } from 'gp-table';
import 'gp-table/dist/index.css';
import 'gp-step/dist/index.css';
import 'gp-toggle/dist/index.css';
import 'gp-datepicker/dist/index.css';
import './style.scss';
import MaskedInput from 'react-text-mask';
import ModalImprimir from './ModalImprimir';
import SelectParentesco from '../../component/Select/SelectParentesco';

const SimulacaoPensao = () => {

    const validationForm = {
        cpf: {
            required: true,
            validator: [{
                tipo: validateCpf,
                error: Message.MSG002_CPF_INVALIDO,
            }]
        },
        obito: {
            required: true
        },
        entradaFolha: {
            required: true
        }
    };

    const { hasPermission } = useSecurityContext();
    const [hasCPF, setHasCPF] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalConfirmRubricas, setShowModalConfirmRubricas] = useState(false);
    const [showModalRubricas, setShowModalRubricas] = useState(false);
    const [showModalImprimir, setShowModalImprimir] = useState(false);
    const [isCalculo, setIsCalculo] = useState(false);
    const initialValuesSchema = {
        cpf: '',
        nome: '',
        sexo: 1,
        nascimento: '',
        cargo: '',
        orgao: '',
        vinculo: '',
        obito: undefined,
        entradaFolha: undefined,
        vinculos: [{
            cargo: ''
        }],
        processo: ''
    }
    const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
    const [beneficios, setBeneficios] = useState({ reajustes: [], rubricas: [] });
    const [vinculo, setVinculo] = useState({});
    const [dependentes, setDependentes] = useState([]);
    const [hasObito, setHasObito] = useState(false);
    const [hasAlteracao, setHasAlteracao] = useState(false);
    const [rubricas, setRubricas] = useState([]);
    const [dadosEdicao, setDadosEdicao] = useState(undefined);
    const [dadosEdicaoRubricas, setDadosEdicaoRubricas] = useState(undefined);
    const [dadosExcluir, setDadosExcluir] = useState(undefined);
    const [hasObitoAfter2020, setHasObitoAfter2020] = useState(false);
    const [dadosImprimir, setDadosImprimir] = useState({});

    const obterBeneficios = async (params) => {
        try {

            const response = await Services.obterPensao(vinculo.id, params);
            const beneficio = response.data;
            setBeneficios(beneficio);
            setDependentes(beneficio.dependentes);
            setRubricas(beneficio.rubricas);

        } catch (error) {
            setIsCalculo(false);
        }
    }

    const formatarRequestDataObito = () => {
        return hasObito ? applyMaskAnoMesDia(values.obito) : format((values.obito), 'yyyy-MM-dd');
    }

    const calcular = () => {
        setIsCalculo(true);
        const params = {
            obito: formatarRequestDataObito(),
            entradaFolha: format((values.entradaFolha), 'yyyy-MM-dd')
        }
        obterBeneficios(params);
    }

    const recalcular = () => {
        if (hasAlteracao || rubricas.some(item => item.hasAlteracao)) {
            const params = {
                obito: formatarRequestDataObito(),
                entradaFolha: format((values.entradaFolha), 'yyyy-MM-dd'),
                rubricas: formatarRubricas(),
                dependentes: dependentes
            }

            obterBeneficios(params);
            setHasAlteracao(false);
        }
    }



    const formatarRubricas = () => {
        return rubricas.map(item => {
            if (item.hasAlteracao) {
                return {
                    nome: item.nome,
                    valor: item.valor,
                    incorpora: item.incorporaAlteracao
                }
            } else {
                return item;
            }
        })
    }


    const getFooterTable = () => (
        <tr className='table-tr-footer'>
            <td colSpan='2'>TOTAL</td>
            <td className={[hasAlteracao || rubricas.some(item => item.hasAlteracao) ? 'text-danger' : null, 'text-center'].join(" ")}
                data-for='info-total' data-tip="" >
                {applyMaskMonetarioThousandSeparator(beneficios.total)}

                <ReactTooltip id='info-total' place="left" type="error" effect="solid"
                    getContent={(dataTip) =>
                        (hasAlteracao || rubricas.some(item => item.hasAlteracao)) &&
                        <div>
                            <p>A rubrica inserida não está no cálculo,</p>
                            <p>para inserir o valor clique no botão "Recalcular"</p>
                        </div>

                    }
                />
            </td>
            <td></td>

        </tr>
    )

    const getFooterRubricas = () => {
        return !beneficios.descontoReferencia && beneficios.reajustes.length === 0 ? getFooterTable() : <></>
    }

    const getFooterDesconto = () => {
        return beneficios.reajustes.length === 0 ? getFooterTable() : <></>;
    }

    const obterTRRubricas = () => {
        if (rubricas) {
            return rubricas.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr key={index}>
                            <td className='text-left'>{item.nome}</td>
                            <td className='text-center'>
                                <ToggleSwitch
                                    id={'inconpora' + index}
                                    disabled={false}
                                    item={item}
                                    value={item.incorpora}
                                    onChange={onChangeToggle} />
                            </td>
                            <td className={[hasAlteracao || item.hasAlteracao ? 'text-danger' : null, 'text-center'].join(" ")}
                                data-for='info' data-tip="">
                                {applyMaskMonetarioThousandSeparator(item.valor)}
                                <ReactTooltip id='info' place="left" type="error" effect="solid"
                                    getContent={(dataTip) =>
                                        (hasAlteracao || item.hasAlteracao) &&
                                        <div>
                                            <p>A rubrica inserida não está no cálculo,</p>
                                            <p>para inserir o valor clique no botão "Recalcular</p>
                                        </div>

                                    }
                                />
                            </td>
                            <td className='text-center'>
                                <button
                                    type="button"
                                    onClick={(event) => onEditarRubricas(item, index)}
                                    className="btn btn-outline-success btn-sm ml-2" >
                                    <i className="fa fa-edit"></i>
                                </button>
                                <button
                                    type="button"
                                    onClick={(event) => openModalConfirmRubricas(event, item)}
                                    className="btn btn-outline-danger btn-sm ml-2" >
                                    <i className="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        {rubricas.length === index + 1 ? getFooterRubricas() : ''}
                    </Fragment>
                )
            });
        }
    }

    const obterTRDesconto = () => {
        if (beneficios && beneficios.descontoReferencia) {
            return (
                <>
                    <tr>
                        <td className='text-left'>{beneficios.descontoDescricao}</td>
                        <td className='text-center'>{applyMaskMonetarioThousandSeparator(beneficios.descontoReferencia)}</td>
                        <td className='text-center'>{applyMaskMonetarioThousandSeparator(beneficios.descontoValor)}</td>
                        <td></td>
                    </tr>
                    {getFooterDesconto()}
                </>

            )
        }
    }

    const obterTRReajuste = () => {
        if (beneficios.reajustes) {
            return beneficios.reajustes.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr >
                            <td className='text-left'>{item.descricao}</td>
                            <td className='text-center'>{item.indice}</td>
                            <td className='text-center'>{applyMaskDouble(item.reajuste)}</td>
                            <td></td>
                        </tr>
                        {beneficios.reajustes.length === index + 1 ? getFooterTable() : ''}
                    </Fragment>
                )
            });
        }
    }

    const onChangeToggle = (item, switchValue) => {
        item.incorporaAlteracao = switchValue;
        item.hasAlteracao = item.incorpora !== switchValue;

        setBeneficios({ ...beneficios });
    }

    const cancelar = () => {
        setIsCalculo(false);
        setValuesSchema(initialValuesSchema);
        setHasAlteracao(false);
        setHasCPF(false);
        setHasObito(false);
        setVinculo({});
        setBeneficios({ reajustes: [], rubricas: [] });
        setDependentes([]);
        setRubricas([]);
        setDadosEdicao(undefined);
        setDadosEdicaoRubricas(undefined);
        setDadosExcluir(undefined);
        setHasObitoAfter2020(false);
    }

    const onBlurCPF = () => {
        const cpf = removeMask(values.cpf);
        if (cpf && cpf.length === 11) {

            const obterCPF = async () => {
                try {
                    const value = await Services.obterCPF(cpf);
                    const val = value.data;

                    if (val.obito) {
                        onBlurAlteracaoObito(new Date(val.obito + ' 00:00:00'));
                    }
                    val.obito = val.obito ? format(parseISO(val.obito), 'dd/MM/yyyy') : undefined;
                    val.entradaFolha = val.entradaFolha ? format(parseISO(val.entradaFolha), 'dd/MM/yyyy') : undefined;

                    if(val.vinculos === undefined || val.vinculos === null || val.vinculos.length === 0) {
                        notification.warning({
                            message: "Antenção!",
                            description: 'Esse CPF não possui vínculos, por favor insira um CPF com vínculos!',
                            duration: 5,
                        });
                        handleReset();
                        return;
                    }

                    setValuesSchema(val);
                    val.vinculos && val.vinculos.length > 0 && setVinculo((prev) => val.vinculos[0]);
                    setHasObito(val.obito ? true : false);
                    setHasCPF(true);

                } catch (error) { console.log(error) }
            }
            obterCPF();
        } else if (valuesSchema.nome !== '') {
            initialValuesSchema.cpf = values.cpf;
            setValuesSchema(initialValuesSchema);
        }
    };

    const { values, errors, handleChange, handleBlur, handleSubmit, handleReset, handleUpdateValues, handleBlurWithoutCallback } =
        useForm(valuesSchema, calcular, validationForm, cancelar, onBlurCPF);

    useEffect(() => {
        handleUpdateValues();
    }, [valuesSchema]);  // eslint-disable-line

    const openModal = (event) => {
        event.preventDefault();
        setShowModal(true);
    }

    const closeModal = (event) => {
        setShowModal(false);
        setDadosEdicao(undefined);
    }

    const openModalConfirm = (event, item) => {
        setDadosEdicao(item);
        event.preventDefault();
        setShowModalConfirm(true);
    }
    const openModalConfirmRubricas = (event, item) => {
        event.preventDefault();
        setDadosExcluir(item);
        setShowModalConfirmRubricas(true);
    }

    const openModalImprimir = (event) => {
        event.preventDefault();
        setDadosImprimir({
            obito: formatarRequestDataObito(),
            entradaFolha: format((values.entradaFolha), 'yyyy-MM-dd'),
            rubricas: formatarRubricas(),
            dependentes: dependentes,
            processo: values.processo,
            cpf: values.cpf,
            nome: values.nome,
        });
        setShowModalImprimir(true);
    }

    const closeModalConfirm = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShowModalConfirm(false);
    }

    const closeModalConfirmRubricas = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShowModalConfirmRubricas(false);
    }

    const openModalRubricas = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShowModalRubricas(true);
    }

    const closeModalRubricas = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShowModalRubricas(false);
        setDadosEdicaoRubricas(undefined);
    }

    const onChangeCargo = (event) => {
        const item = values.vinculos[event.target.value];
        setVinculo(item);
    }

    const openCalendar = (date) => {
        setValuesSchema({
            ...values,
            obito: date
        });
    }

    const openCalendarFolha = (date) => {

        setValuesSchema({
            ...values,
            entradaFolha: date
        });
    }

    const adicionarDependentes = (item) => {
        if (dadosEdicao) {
            setDependentes(dependentes.map((dependente, index) => index === item.index ? item : dependente));
        } else {
            setDependentes([
                ...dependentes,
                item
            ]);
        }
        closeModal();
        setHasAlteracao(true);
    }

    const adicionarRubricas = (item) => {
        if (dadosEdicaoRubricas) {
            setRubricas(rubricas.map((rubrica, index) => index === item.index ? item : rubrica));
            closeModalRubricas();
        } else {
            setRubricas([
                ...rubricas,
                item
            ]);
        }
        setHasAlteracao(true);
    }

    const onEditar = (item, index) => {
        item.index = index;
        setDadosEdicao(item);
        setShowModal(true);
    }

    const onExcluir = () => {
        setDependentes(dependentes.filter(item => item !== dadosEdicao));
        setDadosEdicao();
        setHasAlteracao(true);
        closeModalConfirm();
    }

    const onEditarRubricas = (item, index) => {
        item.index = index;
        setDadosEdicaoRubricas(item);
        openModalRubricas();
    }

    const onExcluirRubricas = () => {
        setRubricas(rubricas.filter(item => item !== dadosExcluir));
        setHasAlteracao(true);
        closeModalConfirmRubricas();
    }

    const onCloseModalImprimir = () => {
        setShowModalImprimir(false);
    }

    const onBlurAlteracaoObito = (date) => {
        const data = isValid(date) ? format((date), 'yyyy-MM-dd') : undefined;
        setHasObitoAfter2020(isAfter(date, new Date(2019, 11, 31)));
        if (beneficios.obito && beneficios.obito !== data) {
            setHasAlteracao(true);
        }
    }

    const onBlurAlteracaoEntradaFolha = (date) => {
        const data = isValid(date) ? format((date), 'yyyy-MM-dd') : undefined;
        if (beneficios.entradaFolha && beneficios.entradaFolha !== data) {
            setHasAlteracao(true);
        }
    }

    const isExibeRecalcular = () => hasAlteracao || rubricas.some(item => item.hasAlteracao);

    const columnsProcesso = useMemo(() =>
        [
            {
                Header: "Processo",
                accessor: "processo",
                alignItems: AlignType.CENTER,
            },
            {
                Header: "Finalidade",
                accessor: "finalidade",
                alignItems: AlignType.CENTER
            },
            {
                Header: "Interessado(s)",
                accessor: "interessado",
                alignItems: AlignType.CENTER
            },
        ], []
    )


    const isExibeStep = () => hasPermission({ hasAnyRole: Permissions.SIMULACAO_PENSAO.INSERIR_DEPENDENTES })

    return (
        <Fragment>
            <Secured hasAllRole={Permissions.SIMULACAO_PENSAO.SIMULACAO_PENSAO} isShowErro403={true}>
                <div className='col-12'>
                    <Painel titulo='Cálculo de Pensão' classPainel="container-fluid">
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-lg-4 col-xl-2 input-group-sm">
                                    <CPF
                                        id='cpf'
                                        label='CPF'
                                        values={values}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        disabled={isCalculo}
                                        errors={errors}
                                    />
                                </div>
                                <div className='form-group col-12 col-lg-8 col-xl-7 input-group-sm'>
                                    <label htmlFor='nome' className='col-form-label'>Nome do Segurado</label>
                                    <input id='nome' name='nome' className='form-control' value={values.nome} disabled />
                                </div>
                                <div className='form-group col-md-6 col-lg-6 col-xl-3 input-group-sm'>
                                    <label htmlFor='dataNascimento' className='col-form-label'>Data de Nascimento</label>
                                    <input id='dataNascimento' name='dataNascimento' className='form-control' value={values.nascimento} disabled />
                                </div>
                                <div className='form-group col-md-6 col-lg-6 col-xl-4 input-group-sm'>
                                    <label htmlFor='nome' className='col-form-label'>Sexo</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="feminino" id="feminino" value='Feminimo' disabled checked={values.sexo === 'Feminino'} />
                                            <label className="form-check-label" htmlFor="feminino">Feminino</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="masculino" id="masculino" value='Masculino' disabled checked={values.sexo === 'Masculino'} />
                                            <label className="form-check-label" htmlFor="masculino">Masculino</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group col-12 col-lg-7 col-xl-5 input-group-sm'>
                                    <label htmlFor='cargo' className='col-form-label'>Cargo</label>
                                    {
                                        values.vinculos.length <= 1 ?
                                            <input id='cargo' name='cargo' className='form-control'
                                                value={values.vinculos.length > 0 ? values.vinculos[0].cargo : ''} disabled />
                                            :
                                            <select id="cargo" className="form-control col-md-12" onChange={onChangeCargo}>
                                                {values.vinculos.map((item, index) => (
                                                    <option key={item.id} value={index}>
                                                        {item.cargo}
                                                    </option>
                                                ))}
                                            </select>
                                    }
                                </div>
                                <div className='form-group col-md-4 col-lg-5 col-xl-3 input-group-sm'>
                                    <label htmlFor='situacao' className='col-form-label'>Situação Previdenciária</label>
                                    <input id='situacao' name='situacao' className='form-control' value={vinculo && vinculo.situacaoPrevidenciaria ? vinculo.situacaoPrevidenciaria : ''} disabled />
                                </div>
                                <If test={hasObito}>
                                    <div className='form-group col-md-4 col-lg-6 col-xl-2 input-group-sm'>
                                        <label htmlFor='obito' className='col-form-label'>Data de Óbito</label>
                                        <input id='obito' name='obito' className='form-control' value={values.obito} disabled />
                                    </div>
                                </If>
                                <If test={!hasObito}>
                                    <Datepicker id='obito' label='Data de Óbito'
                                        disabled={!hasCPF}
                                        date={values.obito}
                                        typeDatepicker={DatepickerType.DATE}
                                        classes="col-md-4 col-lg-6 col-xl-2"
                                        callback={openCalendar}
                                        handleOnBlur={onBlurAlteracaoObito}
                                        onBlur={handleBlurWithoutCallback}
                                        errors={errors}
                                    />
                                </If>
                                <Datepicker id='entradaFolha' label='Concessão'
                                    disabled={!hasCPF}
                                    date={values.entradaFolha}
                                    typeDatepicker={DatepickerType.DATE}
                                    classes="col-md-4 col-lg-6 col-xl-2"
                                    callback={openCalendarFolha}
                                    handleOnBlur={onBlurAlteracaoEntradaFolha}
                                    onBlur={handleBlurWithoutCallback}
                                    errors={errors}
                                />
                                <div className='form-group col-md-6 col-lg-6 col-xl-3 input-group-sm'>
                                    <label htmlFor='processo' className='col-form-label'>N° do Processo</label>
                                    <MaskedInput id="processo" name="processo"
                                        className={`form-control ${errors.processo && "is-invalid"}`}
                                        guide={false}
                                        mask={MASK_NUMBER()}
                                        onChange={handleChange}
                                        value={values.processo}
                                        maxLength='15'
                                        onBlur={handleBlurWithoutCallback}
                                        disabled={!hasCPF}
                                        autoComplete="off" />
                                </div>
                            </div>
                            <If test={!isCalculo}>
                                <div className='row col-md-12 button-right ml-2'>
                                    <button type='button' className='btn btn-outline-dark mr-3' onClick={handleReset}>
                                        <i className="fas fa-eraser"></i>
                                        Limpar
                                    </button>
                                    <button type="submit" className='btn btn-info'>
                                        <i className='fas fa-calculator'></i>
                                        Calcular
                                    </button>
                                </div>
                            </If>

                            <If test={isCalculo}>
                                <Step titulo='Processos'>
                                    <Table
                                        columns={columnsProcesso}
                                        url={config.BASE_API + '/processos/filter'}
                                        isFilterTable={false}
                                        isFormFilter={false}
                                        message={Message}
                                        filtroLista={{
                                            field: 'pessoa.id',
                                            value: values.pessoaId,
                                            fieldType: 'number',
                                            operator: 'EQ'
                                        }}

                                    />

                                </Step>
                                <Step titulo='Cálculo do Benefício'>
                                    <div className='row col-md-12 button-right ml-2'>
                                        <button type="button" className='btn btn-outline-primary btn-sm mb-3' onClick={openModalRubricas}>
                                            <i className='fas fa-plus'></i>
                                            Rubricas
                                        </button>
                                    </div>
                                    <TableDemostrativa columns={configTable(beneficios)}>
                                        <Fragment>
                                            {obterTRRubricas()}
                                        </Fragment>
                                        <Fragment>
                                            {obterTRDesconto()}
                                        </Fragment>
                                        <Fragment>
                                            {obterTRReajuste()}
                                        </Fragment>
                                    </TableDemostrativa>
                                </Step>
                                <If test={isExibeStep() || dependentes.length > 0}>
                                    <Step titulo='Dependentes'>
                                        <If test={dependentes.length > 0}>
                                            <TableDemostrativa columns={configTableDependentes()}>
                                                <Fragment>
                                                    {
                                                        dependentes.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className='text-left'>{item.nome}</td>
                                                                <td className='text-center'><SelectParentesco value={item.parentesco} readOnly/> </td>
                                                                <td className='text-center'>{format(parseISO(item.nascimento), 'dd/MM/yyyy')}</td>
                                                                <td className='text-center'>{item.percentual}</td>
                                                                <td className='text-center'>{applyMaskDouble(item.valor)}</td>
                                                                <td className='text-center'>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => onEditar(item, index)}
                                                                            title='Editar'
                                                                            className="btn btn-outline-success btn-sm">
                                                                            <i className="fa fa-edit"></i>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            onClick={(event) => openModalConfirm(event, item)}
                                                                            title='Excluir'
                                                                            className="btn btn-outline-danger btn-sm ml-2" >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </Fragment>
                                            </TableDemostrativa>
                                        </If>
                                        <Secured hasAnyRole={Permissions.SIMULACAO_PENSAO.INSERIR_DEPENDENTES}>
                                            <div className='row justify-content-md-center'>
                                                <div className="jumbotron jumbotron-border jumbotron-fluid  col-md-10 p-2">
                                                    <div className="container text-center">
                                                        <If test={dependentes.length === 0}>
                                                            <p className="font-weight-bold mb-0" >Não há dependentes cadastrados no sistema</p>
                                                        </If>
                                                        <a type='' href='/#' className='link' onClick={openModal}>Inserir dependente para Cálculo</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Secured>
                                    </Step>
                                </If>

                                <div className='row col-md-12 button-right'>
                                    <Secured hasAnyRole={Permissions.SIMULACAO_PENSAO.IMPRIMIR_CALCULO_SIMULACAO}>
                                        <button type="button" className='btn btn-outline-primary mr-3'
                                            disabled={isExibeRecalcular()}
                                            onClick={openModalImprimir}>
                                            <i className='fas fa-print'></i>
                                            Imprimir
                                        </button>
                                    </Secured>
                                    <button type='button' className='btn btn-outline-dark mr-3' onClick={handleReset}>
                                        <i className="fas fa-eraser"></i>
                                        Limpar
                                    </button>
                                    <If test={isExibeRecalcular()}>
                                        <button type="button" className='btn btn-info' onClick={recalcular}>
                                            <i className='fas fa-calculator'></i>
                                            Recalcular
                                        </button>
                                    </If>
                                </div>
                            </If>
                        </form>
                        <ModalAdicionarDependentes
                            show={showModal}
                            closeModal={closeModal}
                            dadosEdicao={dadosEdicao}
                            hasObitoAfter2020={hasObitoAfter2020}
                            adicionar={adicionarDependentes} />
                        <ModalConfirm
                            show={showModalConfirm}
                            actionClose={closeModalConfirm}
                            message={Message.CONFIRMACAO_EXCLUSAO}
                            title={Message.TITLE_CONFIRMACAO}
                            actionConfirm={onExcluir}
                        />
                        <ModalAdicionarRubricas
                            show={showModalRubricas}
                            dadosEdicao={dadosEdicaoRubricas}
                            closeModal={closeModalRubricas}
                            adicionar={adicionarRubricas} />
                        <ModalConfirm
                            show={showModalConfirmRubricas}
                            actionClose={closeModalConfirmRubricas}
                            message={Message.CONFIRMACAO_EXCLUSAO}
                            title={Message.TITLE_CONFIRMACAO}
                            actionConfirm={onExcluirRubricas}
                        />
                        <ModalImprimir
                            show={showModalImprimir}
                            closeModal={onCloseModalImprimir}
                            dados={dadosImprimir}
                            vinculoId={vinculo.id}
                        />
                    </Painel>
                </div>
            </Secured >
        </Fragment >
    )

}

export default SimulacaoPensao;