import React, { useState, useEffect } from "react";
import "./styles.scss";
import BarGrafico from "../../component/BarGrafico";
import ReactTooltip from "react-tooltip";
import services from "./services";
import { imprimirRelatorio } from "../../helper/util";
import { applyMaskMonetarioThousandSeparator } from "../../helper/mask";
import { useHistory, useLocation } from "react-router-dom";
//import { Secured } from "../../component";
//import { Permissions } from "../../Constantes/permissions.constants";
import { Button, Card, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const BeneficioEspecial = () => {
  let history = useHistory();
  const { state } = useLocation();
  const [vinculoId, setIdVinculo] = useState({});
  const [beneficio, setBeneficio] = useState({
    beneficioRPPS: {},
    beneficioEspecial: {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state) {
      setBeneficio(state.simulacaoBeneficioEspecial);
      setIdVinculo(state.vinculo);
    } else {
      back();
    }
  }, []);

  const back = () => {
    history.push({ pathname: "/", state: state });
  };

  return (
    <>
      {/*<Secured
        hasAnyRole={Permissions.BENEFICIO_ESPECIAL.BENEFICIO}
        isShowErro403={true}
      >*/}
        <h1>Simulação de Benefício Especial</h1>

        <Row
          gutter={[10, 10]}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="card card-azul">
                <div className="card-body p-0">
                  <div className="titulo-beneficio">Salario Atual </div>
                  <div className="valor-beneficio">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.salarioAtual
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <ReactTooltip
                  id="AP"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        Média das remunerações reajustadas pelo índice INPC para cálculo de aposentadoria.
                      </div>
                    </>
                  )}
              />
              <ReactTooltip
                  id="BE"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        Média das remunerações reajustadas pelo índice IPCA para cálculo de Benefício Especial.
                      </div>
                    </>
                  )}
              />
              <ReactTooltip
                  id="RE"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        Quantidade de remunerações utilizadas para o calculo do Benefício Especial.
                      </div>
                    </>
                  )}
              />
              <div className="card card-amarelo">
                <div className="card-body p-0">
                  <div className="titulo-beneficio">Média para Aposentadoria </div>
                  <div className="valor-beneficio">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.mediaSalarial
                    )}
                    <i
                      className="fa fa-question-circle ml-2"
                      data-for="AP"
                      data-tip=""
                    ></i>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="card card-amarelo">
                <div className="card-body p-0">
                  <div className="titulo-beneficio">Média para Benefício Especial </div>
                  <div className="valor-beneficio">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.mediaSalarialEspecial
                    )}
                    <i
                      className="fa fa-question-circle ml-2"
                      data-for="BE"
                      data-tip=""
                    ></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="card card-verde">
                <div className="card-body p-0">
                  <div className="titulo-beneficio"> Tempo de Contribuição em Anos</div>
                  <div className="valor-beneficio">
                    {beneficio.tempoContribuicaoFormatado}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="card card-verde">
                <div className="card-body p-0">
                  <div className="titulo-beneficio"> Tempo de Contribuição em Meses</div>
                  <div className="valor-beneficio">
                    {beneficio.tempoContribuicaoMesesFormatado}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="card card-azul">
                <div className="card-body p-0">
                  <div className="titulo-beneficio">Quantidade de Remunerações </div>
                  <div className="valor-beneficio">
                      {beneficio.quantidadeDeRemuneracoes}
                      <i
                        className="fa fa-question-circle ml-2"
                        data-for="RE"
                        data-tip=""
                      ></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={10} className="grider">
            <Col xs={24} sm={24} md={12} lg={24}>
              <div className="card card-branco">
                <div className="label-card font-weight-bold" style={{color:"red"}}>
                Para apuração correta da estimativa do valor do Benefício Especial é 
                necessário que a Quantidade de Remunerações seja igual ao período 
                contributivo desde a competência de julho de 1994 ou, caso seja posterior à 
                referida competência, desde o início da contribuição.
                ( Tempo de contribuição em meses = Quantidade de remunerações);
                </div>
              </div>
            </Col>
          </Row>         

          <Row gutter={10} className="grider">
            <ReactTooltip
                  id="CSBE"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        Corresponde ao valor da contribuição atual sem realizar a opção pelo Benefício Especial.
                      </div>
                    </>
                  )}
              />
              <ReactTooltip
                  id="CCBE"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        Corresponde ao possivel valor de contribuição após optar por limitar o benefício ao teto do RGPS.
                      </div>
                    </>
                  )}
              />
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                title={
                  <h5 className="titulo-b">
                    Contribuições
                  </h5>
                }
                style={{ height: "100%", flex: 1, }}
              >
                <div className="box">
                  <div className="label-card font-weight-bold">
                    Valor contribuição atual
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioRPPS.valorMensalContribuicao
                    )}
                    <i
                        className="fa fa-question-circle ml-2"
                        data-for="CSBE"
                        data-tip=""
                    ></i>
                  </div>
                  <div className="label-card font-weight-bold">
                    Valor com a opção do BE
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial.valorMensalContribuicao
                    )}
                    <i
                        className="fa fa-question-circle ml-2"
                        data-for="CCBE"
                        data-tip=""
                    ></i>
                  </div>
                  {/*<div className="label-card font-weight-bold">
                    Valor Mensal previsto aposentadoria
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioRPPS
                        .valorMensalPrevistoAposentadoriaBruto
                    )}
                  </div>*/}

                  
                  {/*
                  <div className="label-card font-weight-bold mt-4">
                    Valor mensal previsto de contribuição previdenciária
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioRPPS
                        .valorMensalPrevistoContribuicaoPrevidenciaria
                    )}
                  </div>

                  <div className="label-card font-weight-bold mt-4">
                    Valor líquido mensal previsto de aposentadoria
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioRPPS
                        .valorMensalPrevistoAposentadoriaLiquido
                    )}
                  </div>

                    */}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                title={
                  <h5 className="titulo-b " data-for="info" data-tip="">
                    Projeção de Aposentadoria + Benefício Especial
                  </h5>
                }
                style={{ height: "100%", flex: 1 }}
              >
                <ReactTooltip
                  id="info"
                  place="bottom"
                  type="success"
                  effect="solid"
                  backgroundColor="#12481a"
                  getContent={(dataTip) => (
                    <>
                      <div>
                        O Benefício Especial é um benefício de natureza
                        indenizatória
                      </div>
                      <div>
                        assegurada ao servidor civil estadual que optar por ter
                        a sua aposentadoria{" "}
                      </div>
                      <div>limitada ao valor do teto do RGPS.</div>{" "}
                    </>
                  )}
                />
                <div className="box">
                  <div className="label-card font-weight-bold">
                    Valor mensal de Contribuição limitado ao teto do RGPS
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial.valorMensalContribuicao
                    )}
                  </div>

                  <div className="label-card font-weight-bold">
                    Valor mensal previsto de aposentadoria (teto RGPS)
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial
                        .valorMensalPrevistoAposentadoriaBruto
                    )}
                  </div>

                  {/*
                  <div className="label-card font-weight-bold mt-4">
                    Valor mensal previsto de contribuição previdenciária
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial
                        .valorMensalPrevistoContribuicaoPrevidenciaria
                    )}
                  </div>
                  */}

                  <div className="label-card font-weight-bold">
                    Valor mensal previsto do Benefício Especial
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial
                        .valorMensalPrevistoBeneficioEspecial
                    )}
                    <i
                      className="fa fa-question-circle ml-2"
                      data-for="infoValorMensalPrevisto"
                      data-tip=""
                    ></i>
                  </div>

                  <ReactTooltip
                    id="infoValorMensalPrevisto"
                    place="bottom"
                    type="success"
                    effect="solid"
                    backgroundColor="#12481a"
                    getContent={(dataTip) => (
                      <>
                        <p>Entenda o cálculo:</p>
                        <p className="mt-3 ml-3">
                          <strong>BE = (MR - TR) * (TC / TT)</strong>
                        </p>
                        <p>
                          <strong>BE:</strong> Benefício Especial
                          <br />
                          <strong>MR:</strong> Média aritmética simples das remunerações 
                          referidas neste artigo correspondentes a 100% (cem por cento) do período 
                          contributivo desde a competência de julho de 1994 ou, caso seja posterior à referida 
                          competência
                          <br />
                          <strong>TR:</strong> Teto do RGPS no mês/ano de opção
                          <br />
                          <strong>TC:</strong> Quantidade de contribuições mensais 
                          efetuadas para os regimes próprios de previdência social da 
                          União, dos estados, do Distrito Federal e dos municípios de que 
                          trata o art. 40 da Constituição federal efetivamente pagas pelo servidor 
                          ou pelo membro de que trata o art. 2º desta 
                          Lei Complementar até a data da opção prevista no § 16 do art. 97 da 
                          Constituição do Estado de Goiás
                          <br />
                          <strong>TT:</strong> Tempo Total para o recebimento integral do 
                          Benefício Especial, igual a 520 (quinhentos e vinte).
                        </p>
                      </>
                    )}
                  />

                  <div className="label-card font-weight-bold mt-4">
                    Valor mensal previsto de aposentadoria + Benefício Especial
                  </div>
                  <div className="calculo-desc">
                    {applyMaskMonetarioThousandSeparator(
                      beneficio.beneficioEspecial
                        .valorMensalPrevistoTotalBruto
                    )}
                    <i
                      className="fa fa-question-circle ml-2"
                      data-for="info"
                      data-tip=""
                    ></i>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <BarGrafico simulacaoBeneficioEspecial={beneficio} />
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <Col>
              <Button
                  type="primary"
                  onClick={() => services.getRelatorioBeneficioEspecial(vinculoId).then((response) => {
                    imprimirRelatorio(response);
                  })}
                  style={{ width: "200px", marginRight: "20px" }}
              >
                Relatório Benefício Especial
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<ArrowLeftOutlined />}
                onClick={() => back()}
                style={{ width: "200px" }}
              >
                Voltar
              </Button>
            </Col>
          </Row>
        </Row>
      {/*</Secured>*/}
    </>
  );
};

export default BeneficioEspecial;
