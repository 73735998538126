import React from "react";
import { Card, Col, Form, Row, Select } from "antd";
import {
  ClockCircleOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  IdcardOutlined,
  ManOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";

import "./style.scss";
import { getDateOfYYYYMMddString } from "../../helper/util";

function ResumoContributivo(props) {
  const {
    dataFuncional,
    setIdVinculo,
    setRefreshVinculo,
    dataReferenciaModificada,
    sumula,
    loading,
    resumoTempoRGPS,
    resumoTempoRPPS,
  } = props;
  const [index, setIndex] = React.useState(0);

  return (
    <>
      <>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card
            title="Resumo Contributivo"
            style={{ height: "100%", flex: 1 }}
            loading={loading}
          >
            <Row>
              <Col style={{ display: "flex" }}>
                <UserOutlined className="icon" style={{ fontSize: "23px" }} />{" "}
                <p className="align p-info font-weight-bold">
                  {dataFuncional.nome}
                </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: "flex" }}>
                {dataFuncional.sexo === "Masculino" ? (
                  <ManOutlined className="icon" style={{ fontSize: "23px" }} />
                ) : (
                  <WomanOutlined
                    className="icon"
                    style={{ fontSize: "23px" }}
                  />
                )}{" "}
                <p className="align p-info font-weight-bold">
                  {dataFuncional.sexo}
                </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: "flex" }}>
                <HeartOutlined className="icon" style={{ fontSize: "22px" }} />
                <p className="align p-info font-weight-bold">
                  {dataFuncional.idade} anos
                </p>
                <p className="font align">{dataFuncional.nascimento} </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: "flex" }}>
                <IdcardOutlined className="icon" style={{ fontSize: "23px" }} />
                <p className="align p-info font-weight-bold">
                  {dataFuncional.vinculos[index].cargo}
                </p>
              </Col>
            </Row>

            <Row>
              <Col style={{ display: "flex" }}>
                <FieldTimeOutlined
                  className="icon"
                  style={{ fontSize: "26px" }}
                />
                <p className="align p-info font-weight-bold">
                  {resumoTempoRGPS}
                </p>
                <p className="font align">de Serviço Geral</p>
              </Col>
            </Row>

            <Row>
              <Col style={{ display: "flex" }}>
                <ClockCircleOutlined
                  className="icon"
                  style={{ fontSize: "23px" }}
                />
                <p className="align p-info font-weight-bold">
                  {resumoTempoRPPS}
                </p>
                <p className="font align">de Serviço Público</p>
              </Col>
            </Row>

            {dataReferenciaModificada && (
              <Row>
                <Col span={24}>
                  <Card className="error mt-2">
                    <i className=" mr-2 fas fa-exclamation-triangle"></i>
                    <span>
                      Vínculo inativo: A data de referência do cálculo foi
                      modificada para a data de fim do vinculo:{" "}
                      {getDateOfYYYYMMddString(sumula?.vinculoFim)}
                    </span>
                  </Card>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card
            title="Vínculo Selecionado para Simulações"
            style={{ height: "100%", flex: 1 }}
            hoverable={true}
            loading={loading}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    dataFuncional.vinculos.length < 2
                      ? "Selecionado"
                      : "Selecione"
                  }
                >
                  <Select
                    disabled={dataFuncional.vinculos.length < 2 ? true : false}
                    onChange={(value, i) => {
                      setIdVinculo(value);
                      setRefreshVinculo(value);
                      setIndex((prev) => i.index);
                    }}
                    placeholder="Selecione..."
                    defaultValue={dataFuncional.vinculos[0].id}
                    options={dataFuncional.vinculos.map((value, index) => ({
                      label: `${value.cargo} - ${value.resumoTempoRGPS}`,
                      value: value.id,
                      index: index,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </>
    </>
  );
}

export default ResumoContributivo;
